import * as React from "react";
import {action} from "mobx"
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";


export const GembaPosNeg = inject('rootStore')(observer(
    (props: IBaseView) => {
        const {rootStore: {mobileGembaStore: {dsGembaFinding}}} = props;


        const click = (posneg: string) => {
            return action(() => {
                if (dsGembaFinding.actual.posneg === posneg) {
                    dsGembaFinding.actual.posneg = '';
                } else {
                    dsGembaFinding.actual.posneg = posneg;
                }
            })
        }


        return (
            <div className={"gemba-posneg"}>
                <button className={dsGembaFinding.actual?.posneg === 'positive' ? "green-active" : "green"}
                        onClick={click('positive')}>Positive
                </button>
                <button className={dsGembaFinding.actual?.posneg === 'negative' ? "red-active" : "red"}
                        onClick={click('negative')}>Negative
                </button>
            </div>
        )
    }
))