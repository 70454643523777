import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {Container} from "reactstrap";
import {MainDiv} from "../../_report/styled";
import {InspectionReport} from "./inspection-report";


@inject('rootStore')
@observer
export class InspectionReportView extends React.Component<IBaseView, {}> {

    render() {
        return (
            <Container>
                <MainDiv>
                    <InspectionReport/>
                </MainDiv>
            </Container>
        )

    }
}