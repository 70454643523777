import {BaseStore, IBaseStore} from "./base-store";
import {gembaColumns, IGemba} from "../models/Gemba";
import {IRootStore} from "../routes/root-store";
import {dsState, IDataset} from "../components/dataset/IDataset";
import {IProject, projectColumns} from "../models/Project";
import {action, computed, observable, runInAction} from "mobx";
import {Dataset} from "../components/dataset/dataset";
import {RouterState} from "mobx-state-router";
import {IProjectActivity, projectActivityColumns} from "../models/ProjectActivity";
import {gembaFindingColumns, IGembaFinding} from "../models/GembaFinding";
import {gembaFindingImage, IGembaFindingImage} from "../models/GembaFindingImage";
import {resizeImage} from "../lib/resize-image";
import axios from "axios";

export interface IMobileGembaStore extends IBaseStore<IGemba> {
    dsProject: IDataset<IProject>;
    dsProjectActivity: IDataset<IProjectActivity>;
    dsGembaFinding: IDataset<IGembaFinding>;
    dsImage: IDataset<IGembaFindingImage>;

    next: () => Promise<void>;
    uploadFile: (acceptFile: any[]) => Promise<void>;
    imageSrc: string;
}


export class MobileGembaStore extends BaseStore<IGemba> implements IMobileGembaStore {

    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsProjectActivity: IDataset<IProjectActivity>;

    @observable
    dsGembaFinding: IDataset<IGembaFinding>;

    /**
     *  Zwischenspeicher bei neuem Finding
     *  onBefore werden sie gesetzt
     *  onAfter werden sie benutzt
     */
    saveArea: string;

    @action.bound
    dsGembaFindingOnBeforeInsert(ds: IDataset<IGembaFinding>) {
        if (ds.cursor !== undefined) {
            this.saveArea = ds.actual.area;
        }
    }


    @action.bound
    dsGembaFindingOnAfterInsert(ds: IDataset<IGembaFinding>) {
        let mx = ds.data.reduce((max: number, record) => {
                if (parseInt(record.findingno) + 1 > max) {
                    max = parseInt(record.findingno) + 1;
                }
                return max
            }, 0
        )
        if (mx === 0) {
            mx = 1
        }
        ds.actual.findingno = mx.toString().padStart(4, '0');
        console.log()
        ds.actual.area = this.saveArea;
        //ds.actual.element = this.saveElement;
    }

    @observable
    dsImage: IDataset<IGembaFindingImage>;


    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/gemba/', gembaColumns);

        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [{field: 'projectno', masterField: 'projectno'}]);

        this.dsProjectActivity = new Dataset<IProjectActivity>('/gridApi/projectactivity/', projectActivityColumns);
        this.dsProjectActivity.setMasterSource(this.ds, [
            {field: 'projectno', masterField: 'projectno'},
            {field: 'link', masterField: 'gembano'}]);

        this.dsGembaFinding = new Dataset<IGembaFinding>('/gridApi/gembafinding/', gembaFindingColumns);
        this.dsGembaFinding.setMasterSource(this.ds, [{field: 'gembano', masterField: 'gembano'}]);
        this.dsGembaFinding.onBeforeInsert = this.dsGembaFindingOnBeforeInsert;
        this.dsGembaFinding.onAfterInsert = this.dsGembaFindingOnAfterInsert;

        this.dsImage = new Dataset<IGembaFindingImage>('/gridApi/gembafindingimage/', gembaFindingImage);
        this.dsImage.setMasterSource(this.dsGembaFinding, [
            {
                field: 'gembano',
                masterField: 'gembano'
            },
            {
                field: 'findingno',
                masterField: 'findingno'
            }]);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        switch (toState.routeName) {
            case 'mobilegemba':
                await this.open(toState.params as any);
                await this.dsProject.open();
                await this.dsProjectActivity.open();
                await this.dsGembaFinding.open();
                await this.dsImage.open();
                this.saveArea = '';
                this.dsGembaFinding.last();
                this.dsGembaFinding.insert();
                break;
        }
    }


    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        switch (fromState.routeName) {
            case 'mobilegemba':
                this.dsImage.close();
                if (this.dsGembaFinding.state === dsState.dsInsert || this.dsGembaFinding.state === dsState.dsEdit) {
                    await this.dsGembaFinding.post();
                }
                this.dsGembaFinding.close();
                this.dsProjectActivity.close();
                this.dsProject.close();
                this.ds.close();
                break;
        }
        await Promise.resolve();
    }

    @action.bound
    async next() {
        if (this.dsGembaFinding.state === dsState.dsInsert || this.dsGembaFinding.state === dsState.dsEdit) {
            await this.dsGembaFinding.post();
        }
        runInAction(() => {
            this.dsGembaFinding.last();
            this.dsGembaFinding.insert();
        })
    }

    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/gembafindingimage/fileupload';
        let file
            :
            File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        const formData = new FormData();
        formData.append('type', 'gembafindingimage');
        formData.append('file', file);
        formData.append('gembano', this.ds.actual.gembano);
        formData.append('findingno', this.dsGembaFinding.actual.findingno);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        await axios.post(url, formData, config);
        await this.dsImage.refresh(this.dsImage.cursor);
        await runInAction(async () => {
            this.dsImage.last();
        });
    }

    @computed
    get imageSrc(): string {
        return this.dsImage.data.length > 0 ? "/gridApi/image/gembafindingimage/" + this.dsImage.actual.gembano + "/" + this.dsImage.actual.findingno + "/" + this.dsImage.actual.image : '';
    }

}