import * as React from "react";
import {NotFoundView} from "../views/standard/not-found-view";

import {TaskTableView} from "../views/task/task-table-view";

import {AuditTemplateTableView} from "../views/audit/template/audit-template-table-view";
import {AuditTemplateDetailView} from "../views/audit/template/audit-template-detail-view";
import {AuditTemplateQuestionView} from "../views/audit/template/audit-template-question-view";
import {AuditTableView} from "../views/audit/order/order/audit-table.view";


import {CustomerTableView} from "../views/basedata/customer/customer-table-view";
import {CustomerDetailView} from "../views/basedata/customer/customer-detail-view";

import {PpapTemplateTableView} from "../views/ppap/template/ppap-template-table-view";
import {PpapTemplateDetailView} from "../views/ppap/template/ppap-template-detail-view";
import {PpapTemplateElementView} from "../views/ppap/template/ppap-template-element-view";

import {PpapOrderTableView} from "../views/ppap/order/ppap-order-table-view";
import {PpapOrderDetailView} from "../views/ppap/order/ppap-order-detail-view";


import {ProductTableView} from "../views/basedata/product/product-table-view";
import {ProductDetailView} from "../views/basedata/product/product-detail-view";


import {ProjectTableView} from "../views/project/project/project-table-view";
import {ProjectDetailView} from "../views/project/project/project-detail-view";
import {ProjectDetailActivityView} from "../views/project/project/project-detail-activity-view";

import {SupplierTableView} from "../views/basedata/supplier/supplier-table-view";
import {SupplierDetailView} from "../views/basedata/supplier/supplier-detail-view";

import {UserTableView} from "../views/administration/user/user-table-view";
import {UserDetailView} from "../views/administration/user/user-detail-view";

import {UsergroupDetailView} from "../views/administration/usergroup/usergroup-detail-view";

import {CatalogTableView} from "../views/basedata/catalog/catalog-table-view";
import {CatalogDetailView} from "../views/basedata/catalog/catalog-detail-view";
import {UsergroupTableView} from "../views/administration/usergroup/usergroup-table-view";
import {CatalogEntryView} from "../views/basedata/catalog/catalog-entry-view";

import {LoginView} from "../views/authentication/login-view";

import {ConfigurationView} from "../views/configuration/configuration/configuration-view";


import {Test2View} from "../views/test/test2-view";
import {InspectionPlanTableView} from "../views/inspection/plan/inspection-plan-table-view";
import {InspectionPlanDetailView} from "../views/inspection/plan/inspection-plan-detail-view";
import {InspectionPlanQuestionView} from "../views/inspection/plan/inspection-plan-question-view";
import {InspectionTableView} from "../views/inspection/inspection/inspection-table-view";
import {InspectionDetailView} from "../views/inspection/inspection/inspection-detail-view";
import {InspectionQuestionView} from "../views/inspection/inspection/inspection-question-view";
import {InspectionCollectView} from "../views/inspection/collect/inspection-collect-view";
import {InspectionReportView} from "../views/inspection/report2/Inspection-report-view";
import {ChangePasswordView} from "../views/authentication/change-password-view";
import {DashboardView} from "../views/dashboard/dashboard-view";
import {ProjectActionTableView} from "../views/project/project/project-action-table-view";
import {NoPermissionView} from "../views/standard/no-permission-view";
import {AuditDetailView} from "../views/audit/order/order/audit-detail-view";
import {TaskDetailView} from "../views/task/task-detail-view";
import {AuditReportView} from "../views/audit/order/report/audit-report-view";
import {GembaTableView} from "../views/gemba/order/gemba-table-view";
import {GembaDetailView} from "../views/gemba/order/detail/gemba-detail-view";
import {GembaReportView} from "../views/gemba/order/report/gemba-report-view";
import {ActivityTableView} from "../views/activity/activity-table-view";
import {AuditCollectOnline} from "../views/audit/collect/collect/audit-collect-online";
import {AuditCollectOffline} from "../views/audit/collect/collect/audit-collect-offline";
import {AuditCollectCollectOnlineView} from "../views/audit/collect/collect-collect/audit-collect-collect-online-view";
import {
    AuditCollectCollectOfflineView
} from "../views/audit/collect/collect-collect/audit-collect-collect-offline-view";
import {PPAPReportView} from "../views/ppap/report/ppap-report-view";
import {GembaTemplateTableView} from "../views/gemba/template/gemba-template-table-view";
import {GembaTemplateDetailView} from "../views/gemba/template/gemba-template-detail-view";
import {GembaCollectOnline} from "../views/gemba/collect/collect/gemba-collect-online";
import {MobileActivityListView} from "../views/mobile/activity/mobile-activity-list-view";
import {MobileGembaCollectView} from "../views/mobile/gemba/collect/mobile-gemba-collect-view";

// name!!! not pattern, component or similar
export const viewMap = {
    home: <DashboardView/>,
    login: <LoginView/>,
    nopermission: <NoPermissionView/>,
    notFound: <NotFoundView/>,
    settings: <ChangePasswordView/>,
    //audit
    audittemplatetable: <AuditTemplateTableView/>,
    audittemplate: <AuditTemplateDetailView/>,
    audittemplatequestion: <AuditTemplateQuestionView/>,
    audittable: <AuditTableView/>,
    audit: <AuditDetailView/>,
    auditreport: <AuditReportView/>,
    auditcollectonline: <AuditCollectOnline/>,
    auditcollectoffline: <AuditCollectOffline/>,
    auditcollectcollectonline: <AuditCollectCollectOnlineView/>,
    auditcollectcollectoffline: <AuditCollectCollectOfflineView/>,
    // configuration
    configuration: <ConfigurationView/>,
    // administration
    usertable: <UserTableView/>,
    user: <UserDetailView/>,
    usergrouptable: <UsergroupTableView/>,
    usergroup: <UsergroupDetailView/>,
    catalogtable: <CatalogTableView/>,
    catalog: <CatalogDetailView/>,
    catalogentry: <CatalogEntryView/>,
    // base data
    producttable: <ProductTableView/>,
    product: <ProductDetailView/>,
    customertable: <CustomerTableView/>,
    customer: <CustomerDetailView/>,
    suppliertable: <SupplierTableView/>,
    supplier: <SupplierDetailView/>,
    // project
    //
    projecttable: <ProjectTableView/>,
    project: <ProjectDetailView/>,
    projectactivity: <ProjectDetailActivityView/>,
    projectactivityactiontable: <ProjectActionTableView/>,
    //PPAP
    ppapstandardtable: <PpapTemplateTableView/>,
    ppapstandard: <PpapTemplateDetailView/>,
    ppapstandardelement: <PpapTemplateElementView/>,
    //
    ppapordertable: <PpapOrderTableView/>,
    ppaporder: <PpapOrderDetailView/>,
    ppapreport: <PPAPReportView/>,
    //
    inspectionplantable: <InspectionPlanTableView/>,
    inspectionplan: <InspectionPlanDetailView/>,
    inspectionplanquestion: <InspectionPlanQuestionView/>,
    //
    inspectiontable: <InspectionTableView/>,
    inspection: <InspectionDetailView/>,
    inspectionquestion: <InspectionQuestionView/>,
    inspectionreport: <InspectionReportView/>,
    inspectioncollect: <InspectionCollectView/>,
    //action
    actiontable: <TaskTableView/>,
    action: <TaskDetailView/>,
    //gemba
    gembatemplatetable: <GembaTemplateTableView/>,
    gembatemplate: <GembaTemplateDetailView/>,
    gembatable: <GembaTableView/>,
    gemba: <GembaDetailView/>,
    gembareport: <GembaReportView/>,
    gembacollectonline: <GembaCollectOnline/>,

    activitytable: <ActivityTableView/>,

    mobilelist: <MobileActivityListView/>,
    mobilegemba: <MobileGembaCollectView/>,

    test: <Test2View/>,
    test2: <Test2View/>

};
