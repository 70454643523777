import * as React from 'react';
import {IBaseView} from "../../../../_base/base-view";
import {IGembaFindingImage} from "../../../../../models/GembaFindingImage";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";

interface IGembaFindingImageImg extends IBaseView {
    images: IGembaFindingImage[];
}

@inject('rootStore')
@observer
export class GembaFindingImageImg extends React.Component<IGembaFindingImageImg, {}> {

    @computed
    get src(): string {
        const {images} = this.props;
        return images.length > 0 ? "/gridApi/image/gembafindingimage/" + images[0].gembano + "/" + images[0].findingno + "/" + images[0].image : '';
    }


    render() {
        const {images} = this.props;
        return (
            <img style={{maxWidth: '100%', height: '210px'}} src={this.src}
                 alt={images.length > 0 ? images[0].image : ""}/>
        )
    }
}