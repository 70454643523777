import {cl_gray400} from "../../_theme/colors";
import {MenuFileUpload} from "../menu/menu-file-upload";
import {IconButton} from "../styled/icon-button";
import {action} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UncontrolledTooltip} from "reactstrap";
import * as React from "react";
import {dsState, IDataset} from "../../../components/dataset/IDataset";
import {ILang} from "../../../language/ILang";
import {observer} from "mobx-react";


interface ILogo {
    label: string;
    src: string;
    ds: IDataset<any>;
    fieldName: string;
    uploadFile: any;
    lang: ILang;
    width: number;
    height: number;
}


export const Logo = observer(({label, src, ds, fieldName, uploadFile, lang, width, height}: ILogo) => {
    return (
        <div style={{width: '100%',}}>
            <p style={{margin: 0}}>{label}</p>
            <div>
                {src ?
                    <img src={src}
                         alt=""
                         style={{
                             display: 'inline-block',
                             width: width,
                             height: height,
                             marginLeft: 'auto',
                             marginRight: 'auto',
                             objectFit: 'contain',
                             border: '1px solid ' + cl_gray400,
                             marginBottom: '1rem'
                         }}/> :
                    <div style={{
                        display: 'inline-block',
                        width: width,
                        height: height,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: '1px solid ' + cl_gray400,
                        position: "relative",
                        top: 0

                    }}/>
                }
                <div style={{
                    top: 0,
                    width: 80,
                    height: height,
                    display: 'inline-block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                    {ds.state === dsState.dsEdit ?
                        <>
                            <MenuFileUpload
                                id={label + "_btn-insert"}
                                onDrop={uploadFile}
                                disabled={ds.cursor === undefined}
                            />
                            <IconButton
                                id={label + "_btn-delete"}
                                onClick={action(() => {
                                    ds.actual[fieldName] = ""
                                })}><FontAwesomeIcon icon="minus"
                            />
                            </IconButton>
                            <UncontrolledTooltip
                                placement="bottom-end"
                                target={label + "_btn-delete"}
                            >
                                {lang.DELETE}
                            </UncontrolledTooltip>
                        </> :
                        null}
                </div>
            </div>
        </div>
    )
})