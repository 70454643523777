import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {TableGrid} from "../../_base/styled/table-grid";
import {TableLayout} from "../../_base/styled/layout/table-layout";


@inject('rootStore')
@observer
export class PpapOrderTableView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {ppapStore: {ds}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="ppaporder"
                    icon="box-check"
                    title='PPAP_ORDERS'
                    ds={ds}
                    singleRouteName='ppaporder'
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        )
    }
}