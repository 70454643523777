import {ILangKeys} from "../language/ILang";
import {enumToArray} from "../components/lib/enumToArray";
import {IField} from "../components/dataset/IField";
import {userColumns} from "./User";
import {dateCellRenderer} from "../components/dataset/IDataset";
import {ProjectActivityLinkRenderer} from "./cell-renderers/ProjectActivityLinkRenderer";
import {auditTemplateColumns} from "./AuditTemplate";
import {ppapStandardColumns} from "./PpapStandard";
import {IPPAPRisklevel, ppapRisklevelText} from "./Ppap";
import {productColumns} from "./Product";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {inspectionPlanColumns} from "./InspectionPlan";
import {includes} from "../components/lib/Includes";
import * as R from 'ramda';
import {StatusRenderer} from "./cell-renderers/StatusRenderer";
import {DuedateRenderer} from "./_helper/duedate-renderer";
import {Status, statusText} from "./Status";
import {projectMemberColumns} from "./ProjectMember";
import {StartdateRenderer} from "./_helper/startdate-renderer";
import {gembaTemplateColumns} from "./GembaTemplate";


export enum ProjectActivityTyp {
    ACTIVITY = 100,
    AUDIT = 200,
    PPAP = 300,
    INSPECTION = 400,
    GEMBA_WALK = 500
}


export const projectActivityTypText = (activityTyp: ProjectActivityTyp): ILangKeys => {
    switch (activityTyp) {
        case ProjectActivityTyp.ACTIVITY:
            return 'PROJECTTASKTYP_TASK';
        case ProjectActivityTyp.AUDIT:
            return 'PROJECTTASKTYP_AUDIT';
        case ProjectActivityTyp.PPAP:
            return 'PROJECTTASKTYP_PPAP';
        case ProjectActivityTyp.INSPECTION:
            return 'PROJECTTASKTYP_INSPECTION'
        case ProjectActivityTyp.GEMBA_WALK:
            return 'PROJECTTASKTYP_GEMBAWALK'
        default:
            return 'Unknown';
    }
};


export interface IProjectActivity {
    projectno: string;
    activityno: string;
    description1: string;
    activitytyp: ProjectActivityTyp;
    link: string;
    phase: string;
    responsible: string;
    startdate: string;
    duedate: string;
    gantt: boolean;
    status: number;
    comment: string;
    createdby: string;
    createdat: string;
    _audittemplate?: string;
    _ppapstandardno?: string;
    _ppaplevel?: string;
    _ppapproductno?: string;
    _planno: string;
    _responsiblesup: string;
    _gembatemplate:string;

}


export const projectActivityColumns: IField<IProjectActivity>[] = [
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        hide: 'always',
        insert: 'hide',
        grid: {
            width: 100
        }

    },
    {
        fieldName: 'activityno',
        label: 'PROJECTTASK_TASKNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        hide: 'table',
        grid: {
            width: 60
        }
    },
    {
        fieldName: 'phase',
        label: 'PROJECTTASK_PHASE',
        dataType: 'string',
        maxLength: 80,
        hide: 'always',
        grid: {
            width: 60
        }
    },

    {
        fieldName: 'link',
        dataType: 'string',
        label: '',
        grid: {
            cellRenderer: ProjectActivityLinkRenderer,
            width: 40
        },
        readOnly: true,
    },
    {
        fieldName: 'activitytyp',
        dataType: 'number',
        label: 'PROJECTTASK_TYP',
        rules: ['integer'],
        input: 'select',
        options: enumToArray(ProjectActivityTyp),
        textFunc: projectActivityTypText,
        defaultValue: ProjectActivityTyp.ACTIVITY,
        //insert: 'show',
        hide: 'table',
        readOnly: true,
        grid: {
            width: 120
        }
    },
    {
        fieldName: 'description1',
        label: 'PROJECTTASK_DESCRIPTION1',
        dataType: 'string',
        insert: 'show',
        grid: {

            flex: 2
        },
    },

    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        hide: 'table',


    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
        hide: 'table'
    },
    {
        fieldName: 'responsible',
        label: 'PROJECTTASK_RESPONSIBLE',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        selectdlg: {
            url: '/gridApi/projectmember/',
            columns: projectMemberColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        grid: {
            width: 150
        }
    },
    {
        fieldName: 'startdate',
        label: 'PROJECTTASK_STARTDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: StartdateRenderer,
            //cellStyle: dateCellStyle,
            width: 100
        },

        input: 'calendar'
    },
    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: DuedateRenderer,
            //cellStyle: dateCellStyle,
            width: 100
        },
        input: 'calendar'
    },
    {
        fieldName: 'gantt',
        dataType: 'boolean',
        label: 'PROJECTTASK_GANTT',
        defaultValue: true,
        input: 'checkbox',
        hide: 'table',
    },
    {
        fieldName: 'status',
        label: 'PPAP_STATUS',
        dataType: 'number',
        input: 'catalog',
        options: enumToArray(Status),
        textFunc: statusText,
        defaultValue: 100,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100,
        }
    },
    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'PROJECT_COMMENT',
        hide: 'table',
        input: 'textarea'
    },

    {
        fieldName: '_audittemplate',
        dataType: 'string',
        label: 'AUDIT_TEMPLATE',
        hide: 'always',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/audittemplate/',
            columns: auditTemplateColumns,
            field: 'audittemplate',
            InserRight: UsergroupRight.UR_AUDIT_TEMPLATE_INSERT
        }
    },

    {
        fieldName: '_ppapstandardno',
        dataType: 'string',
        label: 'PPAP_STANDARD',
        hide: 'always',
        insert: 'show',
        rules: ['mandatory'],
        //defaultValue: 'APQP4Wind',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/ppapstandard/',
            columns: ppapStandardColumns,
            field: 'standardno',
            InserRight: UsergroupRight.UR_PPAP_TEMPLATE_INSERT
        }
    },
    {
        fieldName: '_ppaplevel',
        dataType: 'number',
        label: 'PPAP_RISKLEVEL',
        hide: 'always',
        insert: 'show',
        rules: ['number', 'mandatory'],
        input: 'select',
        textFunc: ppapRisklevelText,
        options: enumToArray(IPPAPRisklevel),
        defaultValue: IPPAPRisklevel.HIGH,
        readOnly: true,
    },
    {
        fieldName: '_ppapproductno',
        label: 'PRODUCT_PRODUCTNO',
        dataType: 'string',
        rules: ['mandatory'],
        hide: 'always',
        insert: 'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/product/',
            columns: productColumns,
            field: 'productno',
            InserRight: UsergroupRight.UR_BASE_PRODUCT_INSERT
        }
    },
    {
        fieldName: '_planno',
        dataType: 'string',
        label: 'INSPECTION_PLAN',
        insert: 'show',
        hide: "always",
        rules: ['mandatory'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/inspectionplan/',
            columns: inspectionPlanColumns,
            field: 'planno',
            InserRight: UsergroupRight.UR_INSPECTION_PLAN_INSERT
        },
    },
    {
        fieldName: '_responsiblesup',
        dataType: 'string',
        label: 'PPAP_RESPONSIBLE_SUPPLIER',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        hide: 'always',
        selectdlg: {
            url: '/gridApi/projectsupplieruser/',
            filter: (actual) => {
                return {projectno: actual.projectno}
            },
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        }
    },
    {
        fieldName: '_gembatemplate',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE',
        hide: 'always',
        insert: 'hide',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/gembatemplate/',
            columns: gembaTemplateColumns,
            field: 'gembatemplate',
            InserRight: UsergroupRight.UR_GEMBA_TEMPLATE_INSERT
        }
    },

];


export let activityColumns: IField<IProjectActivity>[] = R.clone(projectActivityColumns);
activityColumns
    .map((column) => {
        if (includes(['projectno', 'createdby', 'activitytyp'], column.fieldName)) {
            column.hide = undefined
        }

        return column;
    })

