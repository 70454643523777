import * as React from "react";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import styled from "styled-components";
import {cl_blue} from "../../../_theme/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "reactstrap";
import {IGembaFindingImage} from "../../../../models/GembaFindingImage";
import {fileIcon} from "../../../../lib/file-icon";


const Block = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  color: black;
  border: solid 3px ${cl_blue};
  border-radius: 0.5rem;
  padding-top: 1rem;
  padding-left: 0;
  padding-right: 0;
`;

const Headline = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;


`;

const HeadLeft = styled.div`
  flex: 0 0 85%;
  background-color: ${cl_blue};
  color: white;
  border-radius: 0 0.5rem 0.5rem 0;
  padding-left: 0.5rem;
`;


const _HeadRight = ({children, className}: any) =>
    <div className={className}>{children}</div>

const HeadRight = styled(_HeadRight)`
  display: block;
  flex: 1 1 auto;
  background-color: ${props => props.bg};
  color: white;
  border-radius: 0.5rem 0 0 0.5rem;
  text-align: right;
  margin-left: 1rem;
  padding-right: 0.5rem;
`;

const Image = styled.img`
  padding: 1px;
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: 50% 50%;
`;

const Icon = styled.div`
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.color};
`;


const Image2 = ({src, title}: any) => {
    const icon = fileIcon(title);
    return (
        <>
            {
                src ?
                    icon ?
                        <Icon>
                            <FontAwesomeIcon size="10x" icon={["fad", icon]}/>
                        </Icon> :
                        <Image src={src} alt='img'/>
                    : null
            }
        </>
    )
}

@inject('rootStore')
@observer
export class GembaReportPage2 extends React.Component<IBaseView, {}> {


    images = (findingno: string) => {
        const
            {
                rootStore: {
                    gembaStore: {
                        dsReportImages
                    }
                }
            }
                = this.props;
        return dsReportImages.data
            .filter((image) => image.findingno === findingno)
    }

    renderImages = (findingno: string) => {

        const images: IGembaFindingImage[] = this.images(findingno);
        if (images.length > 0) {
            return (
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{padding: '1rem', width: '50%'}}>
                            <div style={{
                                height: '304px',
                                border: '1px solid #666666',
                                padding: '1px',
                            }}>
                                <Image2
                                    src={'/gridApi/image/gembafindingimage/' + images[0].gembano + '/' + images[0].findingno + '/' + images[0].image}
                                    title={images[0].image}
                                />
                            </div>
                            <span style={{textAlign: 'center'}}>
                                <a href={'/gridApi/image/gembafindingimage/' + images[0].gembano + '/' + images[0].findingno + '/' + images[0].image}
                                   download>{images[0].image}</a></span>
                        </td>

                        <td style={{padding: '1rem', width: '50%'}}>
                            {images.length > 1 ?
                                <div>
                                    <div style={{height: '304px', border: '1px solid #666666', padding: '1px'}}>
                                        <Image2
                                            src={'/gridApi/image/gembafindingimage/' + images[1].gembano + '/' + images[1].findingno + '/' + images[1].image}
                                            title={images[1].image}
                                        />
                                    </div>
                                    <span style={{textAlign: 'center'}}><a
                                        href={'/gridApi/image/gembafindingimage/' + images[1].gembano + '/' + images[1].findingno + '/' + images[1].image}
                                        download>{images[1].image}</a></span>
                                </div> : null}

                        </td>
                    </tr>
                    </tbody>
                </table>
            )
        } else return null

    }


    render() {
        const {rootStore: {gembaStore: {dsGembaFinding}}} = this.props;
        return (<div>
            {dsGembaFinding.data.map((finding, index) =>
                <Row key={index} style={{paddingTop: '0.5rem', pageBreakBefore: 'auto', pageBreakInside: 'avoid'}}>
                    <Col>
                        <Block>
                            <h5 style={{paddingLeft: '0.5rem', fontWeight: 300}}>
                                {finding.area}
                                {finding.keyword ?
                                    <>
                                        &nbsp;
                                        <span style={{color: cl_blue, fontWeight: 600}}>&gt;</span>
                                        &nbsp;
                                        {finding.keyword}
                                    </> :
                                    null}
                            </h5>
                            <Headline>
                                <HeadLeft>
                                    <h5 style={{marginTop: 8, fontWeight: 500}}>{finding.finding}</h5>
                                </HeadLeft>
                                <HeadRight bg={cl_blue}>
                                    <h5 style={{marginTop: 8}}>
                                        <FontAwesomeIcon icon={["fas", "star"]}/>
                                        &nbsp;
                                        {/* {auditScoreText(ds.actual.ratingmethod, finding.score)}*/}
                                        {/*{question.score === AuditScore.NOT_APPLICABLE ? 'n.a.' : question.score}*/}
                                    </h5>
                                </HeadRight>
                            </Headline>
                            {this.renderImages(finding.findingno)}
                        </Block>
                    </Col>
                </Row>
            )}
        </div>)
    }

}