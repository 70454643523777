import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import Dropzone from "react-dropzone";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";


export const GembaCamera = inject('rootStore')(observer(
    (props: IBaseView) => {
        const disabled: boolean = false;
        const accept = "image/*";


        const {rootStore: {mobileGembaStore: {uploadFile, imageSrc, dsImage}}} = props;


        return (
            <div className={"gemba-camera"}>
                <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                    <div style={{flex: '1 1 50%'}}>
                        <img src={imageSrc} alt={""}/>
                    </div>
                    <div style={{flex: '1 1 50%'}}>
                        <button style={{margin: 'auto'}} onClick={
                            () => {
                                if (dsImage.data.length > 0) {
                                    dsImage.delete();
                                }
                            }
                        }>
                            <FontAwesomeIcon icon={["fad", "trash"]}/>
                        </button>
                    </div>
                </div>

                <Dropzone onDrop={uploadFile} disabled={disabled} accept={accept}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} >
                            <input {...getInputProps()} capture="environment"/>
                            <button style={{width: '100%', marginTop: '0.5rem'}}>
                                <FontAwesomeIcon icon={["fad", "camera"]}/>
                            </button>
                        </div>
                    )}
                </Dropzone>

            </div>
        )
    }
))