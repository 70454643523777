import {dateCellRenderer} from "../components/dataset/IDataset";
import {ILang, ILangKeys} from "../language/ILang";
import * as R from 'ramda';
import {includes} from "../components/lib/Includes";
import {enumToArray} from "../components/lib/enumToArray";
import {userColumns} from "./User";
import {IField} from "../components/dataset/IField";
import {Status, statusText} from "./Status";
import {ActionLinkRenderer} from "./cell-renderers/ActionLinkRenderer";
import {dateCellStyle} from "./_helper/cell-styles";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {StatusRenderer} from "./cell-renderers/StatusRenderer";
import {DuedateRenderer} from "./_helper/duedate-renderer";
import {projectColumns} from "./Project";

export enum ActionTyp {
    //SIMPLETask=50,         // wird das gebraucht?
    PROJECTTask = 100,     // key1: projectno,      key2:taskno
    AUDITTask = 200,       // key:  auditno,        key2:questionno
    PPAPTask = 300,        // key1: ppapno          key2:elementno
    INSPECTIONTask = 400,   // key1: inspectionno    key2: questionno
    GEMBATask = 500          // key1: gembano         key2: findingno
}

export const actionTypText = (typ: ActionTyp): ILangKeys => {
    switch (typ) {
        case ActionTyp.PROJECTTask:
            return 'PROJECT';
        case ActionTyp.AUDITTask:
            return 'AUDIT';
        case ActionTyp.PPAPTask:
            return 'PPAP';
        // case ActionTyp.SIMPLETask:
        //     return 'ACTION';
        case ActionTyp.INSPECTIONTask:
            return 'INSPECTION'
        case ActionTyp.GEMBATask:
            return 'GEMBA'
        default:
            return 'Unknown';
    }
};

export const actionTypKey = (lang: ILang, typ: ActionTyp): string[] => {
    switch (typ) {
        case ActionTyp.PPAPTask:
            return [lang['PPAP_ORDER'], lang['PPAP_ELEMENTNO'], '', ''];
        case ActionTyp.PROJECTTask:
            return [lang['PROJECT_PROJECTNO'], lang['PROJECTTASK_TASKNO'], '', ''];
        default:
            return ['', '', '', ''];
    }
};

interface IActionLink {
    href: string;
    text: string;
}

// Todo unused?
export const makeActionLink = (typ: ActionTyp, key1: string, key2?: string/*, key3?: string, key4?: string*/): IActionLink => {
    switch (typ) {
        case ActionTyp.PROJECTTask:
            return {
                href: '',
                text: actionTypText(typ) + '/' + key1 + '/' + key2
            };
        case ActionTyp.AUDITTask:
            return {
                href: '',
                text: actionTypText(typ) + '/' + key1 + '/' + key2
            };
        case ActionTyp.GEMBATask:
            return {
                href: '',
                text: actionTypText(typ) + '/' + key1 + '/' + key2
            };
        case ActionTyp.PPAPTask:
            return {
                href: '',
                text: actionTypText(typ) + '/' + key1 + '/' + key2
            };
        // case ActionTyp.SIMPLETask:
        //     return {
        //         href: '',
        //         text: actionTypText(typ)
        //     };
        default:
            return {
                href: '',
                text: ''
            };
    }
};


export interface IAction {
    no: string;
    problem: string;
    action: string;
    conducted: string;
    status: Status;
    completeddat: string;
    typ: number;
    createdby: string;
    createdat: string;
    taskowner: string;
    responsible: string;
    startdate: string;
    duration: number;
    duedate: string;
    key1: string;
    key2: string;
    key3: string;
    key4: string;
    keyword: string;
    comment: string;
    projectno: string;
    activityno: string;
}

export const actionColumns: IField<IAction>[] = [
    {
        fieldName: 'no',
        dataType: 'number',
        label: 'ACTION_NO',
        primaryKey: true,
        rules: ['pk'],
        defaultValue: -1,
        insert: 'hide',
        grid: {
            width: 60,
            suppressSizeToFit: true
        }
    },
    {
        fieldName: 'typ',
        dataType: 'number',
        label: 'ACTION_TYPE',
        rules: ['number'],
        input: 'selectradio',
        options: enumToArray(ActionTyp),
        textFunc: actionTypText,
        defaultValue: ActionTyp.PROJECTTask,
        readOnly: true,
    },
    {
        fieldName: 'key1',
        dataType: 'string',

        //hide: 'table',
        label: 'ACTION_KEY1',
        readOnly: true,
        grid: {
            cellRenderer: ActionLinkRenderer
        }
    },
    {
        fieldName: 'key2',
        dataType: 'string',
        hide: 'table',
        label: 'ACTION_KEY2',
        readOnly: true,
    },
    {
        fieldName: 'key3',
        dataType: 'string',
        hide: 'table',
        label: 'ACTION_KEY3',
        readOnly: true,
    },
    {
        fieldName: 'key4',
        dataType: 'string',
        hide: 'table',
        label: 'ACTION_KEY4',
        readOnly: true,
    },
    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        hide: 'table',
        grid: {
            cellRenderer: dateCellRenderer,
            width: 60
        },
        readOnly: true,
    },
    {
        fieldName: 'taskowner',
        dataType: 'string',
        label: 'ACTION_OWNER',
        readOnly: true,
        hide: 'table',
        useAsFilter: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
    },
    {
        fieldName: 'problem',
        dataType: 'string',
        label: 'ACTION_PROBLEM',
        hide: 'always',
        input: 'textarea'
    },
    {
        fieldName: 'keyword',
        dataType: 'string',
        maxLength: 80,
        label: 'ACTION_KEYWORD',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            flex: 1
        }

    },
    {
        fieldName: 'action',
        dataType: 'string',
        label: 'ACTION_ACTION',
        insert: 'show',
        input: 'textarea',
        hide: 'table'
    },
    {
        fieldName: 'conducted',
        dataType: 'string',
        label: 'ACTION_CONDUCTED',
        input: 'textarea',
        hide: 'table',
    },
    {
        fieldName: 'status',
        dataType: 'number',
        label: 'ACTION_STATUS',
        rules: ['number'],
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(Status),
        useAsFilter: true,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100
        },
        defaultValue: Status.PLANNED
    },
    {
        fieldName: 'responsible',
        dataType: 'string',
        label: 'ACTION_RESPONSIBLE',
        input: 'selectdlg',
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        insert: 'show',
        grid: {
            width: 100,
        },
        //rules: ['mandatory'],
    },
    {
        fieldName: 'duration',
        dataType: 'number',
        label: 'ACTION_DURATION',
        hide: 'table',
    },
    {
        fieldName: 'duedate',
        dataType: 'string',
        label: 'ACTION_DUEDATE',
        insert: 'show',
        input: 'calendar',
        grid: {
            cellRenderer: DuedateRenderer,
            width: 100,
        },
        calendar: {
            popperPlacement: 'top'
        },

    },
    {
        fieldName: 'completeddat',
        dataType: 'string',
        label: 'ACTION_COMPLETEDDAT',
        hide: 'table',
        input: 'calendar',
        grid: {
            //cellRenderer: dateCellRenderer,
            cellStyle: dateCellStyle,
            width: 90,
            suppressSizeToFit: true
        },
        calendar: {
            popperPlacement: 'top'
        }
    },
    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'ACTION_COMMENT',
        input: 'textarea',
        hide: 'table',
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        readOnly: true,
        useAsFilter: true,
        input:'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            //InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
    },
    {
        fieldName: 'activityno',
        label: 'PROJECTTASK_TASKNO',
        dataType: 'string',
        readOnly: true,
        hide: 'always',
    },


];


export const projectActionColumns: IField<IAction>[] = R.clone(actionColumns)
    .filter((c: IField<IAction>) => !includes(['problem', 'createdat', 'createdby', 'typ', 'key3', 'key4', 'projectno'], c.fieldName))
    .map((c: IField<IAction>) => {
            if (includes(['key1', 'key2'], c.fieldName)) {
                c.hide = 'table'
            }
            return c;
        }
    );

export const auditActionColumns: IField<IAction>[] = R.clone(actionColumns)
    .filter((c: IField<IAction>) => !includes(['problem', 'createdat', 'createdby', 'typ', 'key3', 'key4', 'projectno'], c.fieldName))
    .map((c: IField<IAction>) => {
            if (includes(['key1', 'key2',], c.fieldName)) {
                c.hide = 'table'
            }
            return c;
        }
    );

export const gembaActionColumns: IField<IAction>[] = R.clone(actionColumns)
    .filter((c: IField<IAction>) => !includes(['problem', 'createdat', 'createdby', 'typ', 'key3', 'key4', 'projectno'], c.fieldName))
    .map((c: IField<IAction>) => {
            if (includes(['key1', 'key2',], c.fieldName)) {
                c.hide = 'table'
            }
            return c;
        }
    );

export const inspectionActionColumns: IField<IAction>[] = R.clone(actionColumns)
    .filter((c: IField<IAction>) => !includes(['problem', 'createdat', 'createdby', 'typ', 'key3', 'key4', 'projectno'], c.fieldName))
    .map((c: IField<IAction>) => {
            if (includes(['key1', 'key2',], c.fieldName)) {
                c.hide = 'table'
            }
            return c;
        }
    );

export const ppapActionColumns: IField<IAction>[] = R.clone(actionColumns)
    .filter((c: IField<IAction>) => !includes(['problem', 'createdat', 'createdby', 'typ', 'key3', 'key4', 'projectno'], c.fieldName))
    .map((c: IField<IAction>) => {
            if (includes(['key1', 'key2'], c.fieldName)) {
                c.hide = 'table'
            }
            return c;
        }
    );
