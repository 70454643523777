import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {Col, Row} from "reactstrap";
import {DashboardPpapElementStatusChart} from "./dashboard-ppap-element-status-chart";
import {IProjectStatus} from "../../../stores/dashboard-store";
import {inject, observer} from "mobx-react";
import {DashboardPpapStatusChart, IBarValue} from "./dashboard-ppap-status-chart";
import {cl_green, cl_red, cl_yellow} from "../../_theme/colors";
import {Status, statusColor} from "../../../models/Status";
import {PPAP_DOC} from "../../../models/PpapStandardElement";
import moment from "moment";


/**
 *  Berechnet  Background für ppap Bar.
 *  extrahiert aus duedate-renderer
 */
const ppapDuedateColor = (duedate: string, status: Status): string => {
    if (!duedate) {
        return cl_green;
    } else if (moment(moment(duedate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') <= 0 &&
        status !== Status.COMPLETED && status !== Status.APPROVED) {
        return cl_red;
    } else if ((moment(moment(duedate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days') < 7 &&
        moment(moment(duedate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'days')) &&
        status !== Status.COMPLETED && status !== Status.APPROVED) {
        return cl_yellow
    } else return cl_green;
}


interface IDashboardPpapStatus extends IBaseView {
    projectStatus: IProjectStatus;
}


@inject('rootStore')
@observer
export class DashboardPpapStatus extends React.Component<IDashboardPpapStatus, {}> {

    getPPAPStatus = (): IBarValue[] => {
        const {projectStatus} = this.props;
        return projectStatus.ppapstati.map((status) => {
            return {
                ppapno: status.ppapno,
                label: status.productno,
                value: status.elements.filter(e => e.doc === PPAP_DOC.MANDATORY && (e.status === 400 || e.status === 390)).length / status.elements.filter(e => e.doc === PPAP_DOC.MANDATORY).length * 100,
                duedate: status.duedate,
                color: ppapDuedateColor(status.duedate, status.status)
            }
        });
    }


    ppapElements = (status?: number) => {
        const {projectStatus} = this.props;
        return projectStatus.ppapstati.reduce((x: number, ppap) => {
            return status ?
                x + ppap.elements.filter(e => e.doc === PPAP_DOC.MANDATORY && e.status === status).length :
                x + ppap.elements.filter(e => e.doc === PPAP_DOC.MANDATORY).length;
        }, 0);
    }


    render() {
        const {rootStore: {langStore: {lang}}, projectStatus} = this.props;
        this.ppapElements();
        return (
            <>
                <Row>
                    <Col>
                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>
                            {
                                projectStatus.audits.length > 0 ?
                                    <hr style={{margin: '0.25rem 0 '}}/> :
                                    null
                            }
                            Part Qualification
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col md={8} style={{paddingTop: '1rem'}}>
                        <div style={{display: 'block', height: '150px', width: '100%',}}>
                            <DashboardPpapStatusChart
                                orientatation="vertical"
                                data={this.getPPAPStatus()}
                                min={0}
                                max={100}
                            />
                        </div>
                        <div className="simpletext">
                            Status
                        </div>
                    </Col>

                    <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                        <div style={{display: 'block', height: '150px', width: '100%',}}>
                            <DashboardPpapElementStatusChart catValues={[
                                {
                                    category: lang['STATUS_PLANNED'],
                                    color: statusColor(100),
                                    value: this.ppapElements(100)
                                },
                                {
                                    category: lang['STATUS_INPROGRESS'],
                                    color: statusColor(200),
                                    value: this.ppapElements(200)
                                },
                                {
                                    category: lang['STATUS_COMPLETED'],
                                    color: statusColor(300),
                                    value: this.ppapElements(300)
                                },
                                {
                                    category: lang['STATUS_NOT_APPROVED'],
                                    color: statusColor(350),
                                    value: this.ppapElements(350)
                                },
                                {
                                    category: lang['STATUS_COND_APPROVED'],
                                    color: statusColor(390),
                                    value: this.ppapElements(390)
                                },
                                {
                                    category: lang['STATUS_APPROVED'],
                                    color: statusColor(400),
                                    value: this.ppapElements(400)
                                }
                            ]}/>
                        </div>
                        <div className="simpletext">
                            {this.ppapElements()}&nbsp;Elements
                        </div>
                    </Col>
                </Row>
            </>)
    }
}