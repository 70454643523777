import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../_base/base-view";
import {GembaCollectView} from "./gemba-collect-view";


@inject('rootStore')
@observer
export class GembaCollectOnline extends React.Component<IBaseView, {}> {

    onClickFinding = (findingno: string) => async () => {
        const {rootStore: {gembaStore: {dsGembaFinding}}} = this.props;

        if (dsGembaFinding.actual.findingno !== findingno) {
            await dsGembaFinding.post();
            if (dsGembaFinding.locate(['findingno'], {findingno: findingno})) {
                dsGembaFinding.edit();
            }

        }
    }


    render() {
        const {rootStore: {gembaStore: {ds, dsGembaFinding, dsImage}}} = this.props;
        return (
            <GembaCollectView
                gemba={ds.actual}
                gembaFindings={dsGembaFinding.data}
                actFinding={dsGembaFinding.cursor > -1 ? dsGembaFinding.data[dsGembaFinding.cursor].findingno : ''}
                onClickFinding={this.onClickFinding}
                areaColumn={dsGembaFinding.columns.find((column) => column.fieldName === "area")}
                images={dsImage.data}
            />
        )
    }
}