import {IField} from "../components/dataset/IField";


export interface IGembaFinding {
    gembano: string;
    findingno: string;
    finding: string;
    area: string;
    element: string;
    keyword: string;
    posneg: string;
}


export const gembaFindingColumns: IField<IGembaFinding>[] = [
    {
        fieldName: 'gembano',
        dataType: 'string',
        label: 'GEMBA_GEMBANO',
        primaryKey: true,
        rules: ['pk'],
        insert: 'hide',
        hide: 'table'
    },
    {
        fieldName: 'findingno',
        label: 'GEMBA_FINDINGNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        insert: 'hide',
        grid: {
            width: 60,
        }
    },
    {
        fieldName: 'area',
        label: 'GEMBA_AREA',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'keyword',
        label: 'GEMBA_KEYWORD',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
    },
    {
        fieldName: 'element',
        label: 'AUDIT_ELEMENT',
        dataType: 'string',
        maxLength: 80,
        hide: 'always',
        insert: 'hide',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'finding',
        dataType: 'string',
        label: 'GEMBA_FINDING',
        insert: 'show',
        input: 'textarea',
        maxLength: 1024,
        grid: {
            flex: 1
        }
    },
    {
        fieldName: 'posneg',
        dataType: 'string',
        label: 'GEMBA_POSNEG',
        grid: {
            width: 100
        }
    }
];




