import {IBaseView} from "../../../_base/base-view";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {cl_green, cl_red} from "../../../_theme/colors";
import {GembaHeader} from "./gemba-header";
import {GembaPosNeg} from "./gemba-pos-neg";
import {GembaCamera} from "./gemba-camera";
import {GembaFinding} from "./gemba-finding";
import {GembaNext} from "./gemba-next";
import * as React from "react";


const _MobileGembaCollectView = inject('rootStore')(observer(({className}: IBaseView) => {


    return (
        <div className={className}>
            <div className={"gemba-card"}>
                <GembaHeader/>
                <GembaPosNeg/>
                <GembaCamera/>
                <GembaFinding/>
                <GembaNext/>
            </div>
        </div>
    )
}))

export const MobileGembaCollectView = styled(_MobileGembaCollectView)`
  display: block;
  height: 100%;
  width: 100%;
  padding: 0.25rem;

  .gemba-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border: ${props => props.theme.border};
    height: 100%;
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: ${props => props.theme.boxShadow};
    color: ${props => props.theme.color};

    .gemba-header {
      flex: 0 0 auto;
      padding: 0.25rem 0.5rem;
      border-bottom: ${props => props.theme.border};
      text-align: center;
      font-weight: 500;
    }

    .gemba-posneg {
      flex: 0 0 auto;
      padding-top: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      button {
        background-color: white;
        width: 50%;
        border-radius: 0.25rem;
        padding: 0.25rem;
      }

      .green {
        color: ${cl_green};
        border: 2px solid ${cl_green};
      }

      .green-active {
        font-weight: 500;
        color: white;
        background-color: ${cl_green};
        border: 2px solid ${cl_green};
      }

      .red {
        color: ${cl_red};
        border: 2px solid ${cl_red};
      }

      .red-active {
        font-weight: 500;
        color: white;
        background-color: ${cl_red};
        border: 2px solid ${cl_red};
      }
    }

    .gemba-camera {
      flex: 1 1 auto;
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      button {
        font-weight: 500;
        color: ${props => props.theme.color};
        background-color: white;
        width: 100%;
        border: 2px solid ${props => props.theme.color};;
        border-radius: 0.25rem;
        padding: 0.25rem;
      }

      img {
        height: 160px;
        width: 100%;
        text-indent: -100%;
        overflow: hidden;
        border: ${props => props.theme.border};
      }

    }

    .gemba-finding {
      flex: 0 0 auto;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .wrapper {
        margin-top: -1rem;
      }

    }

    .gemba-next {
      flex: 0 0 auto;
      padding: 0.5rem;
      display: flex;
      justify-content: space-evenly;
      margin-top: -1rem;

      button {
        font-weight: 500;
        color: white;
        background-color: ${cl_red};
        width: 100%;
        border: 2px solid ${cl_red};
        border-radius: 0.25rem;
        padding: 0.25rem;
      }
    }
  }
`;