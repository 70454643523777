import * as React from 'react';

import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {action} from "mobx";
import {ProjectActivityTyp} from "../../../models/ProjectActivity";
import {DetailLayout} from "../../_base/styled/layout/detail-layout";
import {TableForm} from "../../_base/styled/table-form";
import {TableGrid} from "../../_base/styled/table-grid";
import {ProjectDetailForm} from "./project-detail-form";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../components/dbform/db-field";

@inject('rootStore')
@observer
export class ProjectDetailActivityView extends React.Component<IBaseView, {}> {

    @action.bound
    goToAction() {
        const {rootStore: {routerStore, projectStore: {dsAction,}}} = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    @action.bound
    goToLink() {
        const {rootStore: {routerStore, projectStore: {dsProjectActivity}}} = this.props;
        switch (dsProjectActivity.actual.activitytyp) {
            case ProjectActivityTyp.AUDIT:
                routerStore.goTo('audit', {auditno: dsProjectActivity.actual.link});
                break;
            case ProjectActivityTyp.PPAP:
                routerStore.goTo('ppap', {auditno: dsProjectActivity.actual.link});
                break;
            case ProjectActivityTyp.INSPECTION:
                routerStore.goTo('inspection', {inspectionno: dsProjectActivity.actual.link});
                break;
            default:
                break;
        }

    }

    render() {
        const {
            rootStore: {
                projectStore: {ds, dsProjectActivity, dsAction, statusActivityOptionsFunc},
                authStore: {username}
            }
        } = this.props;
        return (
            <DetailLayout
                topElement={<ProjectDetailForm
                    icon="project-diagram"
                    title='PROJECT'
                    ds={ds}
                    keyTitle={ds.actual?.projectno + " - " + ds.actual?.project}
                    open={false}
                />}
                mainLeftElement={<TableForm
                    icon="chart-network"
                    title='PROJECTTASK_ACTIVITY'
                    ds={dsProjectActivity}
                    hideMenu={username !== dsProjectActivity.actual?.responsible && username !== ds.actual?.owner}
                    ownerField={"responsible"}
                >
                    <Row>
                        <Col>
                            {/* <DbField ds={dsProjectActivity} field={"projectno"}/>*/}
                            <DbField ds={dsProjectActivity} field={"activityno"}/>
                            {/*<DbField ds={dsProjectActivity} field={"phase"}/>*/}

                            {/*                            <DbField
                                ds={dsProjectActivity}
                                field={"link"}
                                onClickLabel={
                                    (dsProjectActivity.actual?.activitytyp === ProjectActivityTyp.AUDIT && hasUserRight(UsergroupRight.UR_AUDIT_AUDIT)) ||
                                    (dsProjectActivity.actual?.activitytyp === ProjectActivityTyp.PPAP && hasUserRight(UsergroupRight.UR_PPAP_PPAP)) ||
                                    (dsProjectActivity.actual?.activitytyp === ProjectActivityTyp.INSPECTION && hasUserRight(UsergroupRight.UR_INSPECTION_INSPECTION))

                                        ? this.goToLink : undefined
                                }
                            />*/}
                            <DbField ds={dsProjectActivity} field={"status"} optionsFunc={statusActivityOptionsFunc}/>
                            <DbField ds={dsProjectActivity} field={"startdate"}/>
                            <DbField ds={dsProjectActivity} field={"duedate"}/>
                            <DbField ds={dsProjectActivity} field="createdby"/>
                            <DbField ds={dsProjectActivity} field="createdat"/>
                        </Col>
                        <Col>
                            <DbField ds={dsProjectActivity} field={"activitytyp"}/>
                            <DbField ds={dsProjectActivity} field={"description1"}/>
                            <DbField ds={dsProjectActivity} field={"responsible"}/>
                            <DbField ds={dsProjectActivity} field={"comment"}/>
                            <DbField ds={dsProjectActivity} field={"gantt"}/>
                        </Col>
                    </Row>
                </TableForm>
                }
                mainRightElement={<TableGrid
                    icon="tasks"
                    id="action"
                    title='ACTIONS'
                    ds={dsAction}
                    onRowDoubleClicked={this.goToAction}
                    hideInsert={!(dsProjectActivity.actual?.activitytyp === ProjectActivityTyp.ACTIVITY) || !(username === dsProjectActivity.actual?.responsible || username === ds.actual?.owner)}
                    hideDelete={!(dsProjectActivity.actual?.activitytyp === ProjectActivityTyp.ACTIVITY) || !(username === dsProjectActivity.actual?.responsible || username === ds.actual?.owner || !dsProjectActivity.actual)}
                />

                }

            />
        )
    }
}

