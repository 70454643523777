import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../_base/base-view";
import styled from "styled-components";
import {IGemba} from "../../../../models/Gemba";
import {IGembaFinding} from "../../../../models/GembaFinding";
import {GembaFinding} from "./finding/gemba-finding";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {computed} from "mobx";
import {IField} from "../../../../components/dataset/IField";
import {IGembaFindingImage} from "../../../../models/GembaFindingImage";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {MenuInsert} from "../../../_base/menu/menu-insert";

interface IGembaCollectView extends IBaseView {
    gemba: IGemba;
    gembaFindings: IGembaFinding[];
    actFinding: string;
    onClickFinding: (findingno: string) => () => Promise<void>;
    areaColumn: IField<IGembaFinding>;
    images: IGembaFindingImage[];
}


@inject('rootStore')
@observer
class _GembaCollectView extends React.Component<IGembaCollectView, {}> {


    @computed
    get keyTitle() {
        const {rootStore: {gembaStore: {ds}}} = this.props;
        let rv1 = ds.actual?.gembano ?? ""
        let rv2 = ds.actual?.gemba ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : ""
    }


    render() {
        const {
            rootStore: {/*langStore: {lang},*/ gembaStore: {dsGembaFinding}},
            className,
            gembaFindings,
            actFinding,
            onClickFinding,
            areaColumn,
            images
        } = this.props;
        return (
            <div className={className}>
                <div className={'gheader'}>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={["fad", "walking"]}/>
                    </div>
                    <div className="center">
                        <span className="keyTitle">{this.keyTitle ?? ''}</span>
                    </div>
                    <div className={"menu"}>
                        <MenuInsert
                            id={"Id-gemba_insert"}
                            ds={dsGembaFinding}
                            hideDialog={true}
                        />
                        <MenuDelete
                            id={"Id-gemba_delete"}
                            ds={dsGembaFinding}
                        />
                    </div>

                    {/*{lang["GEMBA"]}&nbsp;&nbsp;*/}

                </div>
                <div className={'gbody'}>
                    <div className={'content'}>
                        {
                            gembaFindings ?
                                gembaFindings.map(
                                    (finding, index) => {
                                        //console.log(actFinding === finding.findingno,actFinding , finding.findingno)
                                        return <GembaFinding
                                            key={index}
                                            finding={finding}
                                            active={actFinding === finding.findingno}
                                            onClick={onClickFinding(finding.findingno)}
                                            areaColumn={areaColumn}
                                            images={images}
                                        />
                                    }
                                )
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export const GembaCollectView = styled(_GembaCollectView)`

  /*color: black;*/

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  .gheader {
    flex: 0 0 auto;
    padding: ${props => props.theme.box.header.padding};
    font-size: ${props => props.theme.box.header.fontSize};
    font-weight: ${props => props.theme.box.header.fontWeight};
    background-color: ${props => props.theme.box.header.backgroundColor};
    color: ${props => props.theme.box.header.color};
    border-bottom: 0;
    display: flex;

    .icon {
      flex: 0 0 3rem;
    }

    .center {
      flex: 1 1 auto;
    }

    .menu {
      flex: 0 0 4.5rem;
    }
  }

  .gbody {
    flex: 1 1 auto;


    .content {
      position: absolute;
      top: 43px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }


  }

  .gfooter {
    flex: 0 0 3rem;
    background-color: white;
    color: black;
  }

`;

