import React from 'react';
import {IBaseView} from "../_base/base-view";
import styled from "styled-components";
import {Col, Container, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import {Tile, TileHeader} from "../_base/styled/layout/tile";
import {BaseTableFilterMenu} from "../_base/table-filter/base-table-filter-menu";
import {DashboardProjectCard} from "./dashboard-project-card";
import {UsergroupRight} from "../../models/rights/enum-usergrouprights";

@inject('rootStore')
@observer
class _DashboardView extends React.Component<IBaseView, {}> {


    render() {
        const {className} = this.props;
        const {rootStore: {dashboardStore: {projectStati},authStore: {hasUserRight}}} = this.props;
        return (
            <div className={className}>
                <Container fluid>
                    <Row>
                        <Col style={{paddingLeft: '0.5rem', paddingRight: '0.5rem', paddingTop: '1rem'}}>
                            <Tile>
                                <TileHeader>
                                    <div className="tile-middle">
                                        Dashboard
                                    </div>
                                    <div className="tile-right">
                                        {
                                            hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                                                <BaseTableFilterMenu storeName="dashboardStore"
                                                                     filtername="dashboard"/> :
                                                null
                                        }
                                    </div>
                                </TileHeader>
                            </Tile>
                        </Col>
                    </Row>
                    <Row>
                        {projectStati.map(((projectStatus) =>
                                <Col key={projectStatus.project.projectno} xl={4} lg={6} md={12} sm={12} xs={12}
                                     style={{marginTop: '1rem', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                    <DashboardProjectCard projectStatus={projectStatus}/>
                                </Col>

                        ))
                        }
                    </Row>
                </Container>
            </div>
        )
    }
}


export const DashboardView = styled(_DashboardView)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.main.color};
  background-color: ${props => props.theme.main.bg};
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: ${props => props.theme.boxShadowInset};


  .card {
    background-color: white;
    border-radius: ${props => props.theme.borderRadius};
    border: ${props => props.theme.border};
    box-shadow: ${props => props.theme.boxShadow};

    font-size: 1.3125rem;
    font-weight: 100;
    overflow: hidden;
    text-align: center;
    width: 100%;

    .card-header {
      padding: 1rem;
      font-size: 1rem;
      background-color: white;
      text-align: left;
      border-bottom: ${props => props.theme.border};

      .status-left {
        display: inline;
        color: ${props => props.theme.main.color};
        cursor: pointer;
        align-self: center;
        font-weight: 700;
        text-align: left;


        > .logo {
          display: inline-block;
          width: 2rem;
          height: 2rem;
        }

        :hover {
          color: ${props => props.theme.main.color};
          //text-decoration: underline;
        }
      }

      .status-right {
        display: inline;
        color: ${props => props.theme.main.color};
        align-self: center;
        font-weight: 400;
        text-align: right;
      }
    }

    .cardBody {
      margin-top: 1rem;
      padding: 1.25rem;
    }
  }

  .link {
    display: inline;
    color: ${props => props.theme.main.color};
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;

    :hover {
      color: ${props => props.theme.main.color};
      //text-decoration: underline;
    }
  }

  .simpletext {
    display: inline;
    color: ${props => props.theme.main.color};
    cursor: default;
    font-size: 1rem;
    font-weight: 500;
  }

  .project {
    text-align: left;
  }
`;


