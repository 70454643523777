import * as React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Sticker} from "../_base/styled/sticker";
import {statusColor, statusText} from "../../models/Status";
import {AuditStatisticScore} from "../audit/order/order/top/audit-statistic-score";
import {DashboardTaskStatus} from "./dashboard-task-status";
import {IProjectStatus} from "../../stores/dashboard-store";
import {IBaseView} from "../_base/base-view";
import {action} from "mobx";
import {inject, observer} from "mobx-react";
import {DashboardPpapStatus} from "./ppap/dashboard-ppap-status";
import {DashboardGanttChart} from "./gantt/dashboard-gantt-chart";


interface IDashboardProjectCard extends IBaseView {
    projectStatus: IProjectStatus;
}

@inject('rootStore')
@observer
export class DashboardProjectCard extends React.Component<IDashboardProjectCard, {}> {

    @action.bound
    gotoProject(projectno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('project', {projectno: projectno});
        }
    }

    @action.bound
    gotoAudit(auditno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('audit', {auditno: auditno});
        }
    }

    @action.bound
    gotoTasks(projectno: string, activityno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('projectactivityactiontable', {projectno: projectno, activityno: activityno});
        }
    }

    render() {
        const {rootStore: {langStore: {lang}}, projectStatus} = this.props;
        return (
            <Card>
                <CardHeader style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                    <Row>
                        <Col md={8}>
                            <div className="status-left"
                                 onClick={this.gotoProject(projectStatus.project.projectno)}>
                                <div className="logo">
                                    {projectStatus.project.suppliericon && projectStatus.project.supplierno ?
                                        <img style={{objectFit: 'contain', width: '2rem', height: '2rem'}}
                                             src={"/gridApi/supplierlogo/download/" + projectStatus.project.supplierno + "/" + projectStatus.project.suppliericon}
                                             alt=""
                                        /> :
                                        <FontAwesomeIcon icon="project-diagram"/>

                                    }
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                {projectStatus.project.project}
                            </div>
                        </Col>
                        <Col md={4} className="status-right">
                            <Sticker color={statusColor(projectStatus.project.status)}>
                                {lang[statusText(projectStatus.project.status)]}
                            </Sticker>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{padding: '0.625rem'}}>
                    {projectStatus.audits.length > 0 ?
                        <Row>
                            <Col>
                                <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>Audit</div>
                            </Col>
                        </Row> :
                        null
                    }
                    <Row>
                        {projectStatus.audits
                            .map((audit, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Col md={4} style={{paddingTop: '1rem'}}>
                                                <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                    <AuditStatisticScore
                                                        auditScore={audit.score}
                                                        ratingMethod={audit.ratingMethod}
                                                    />
                                                </div>
                                                <div className="link"
                                                     onClick={this.gotoAudit(audit.auditno)}>{audit.activity.description1}</div>
                                            </Col>
                                            {i % 2 === 1 ?
                                                projectStatus.audits[i].actions.length > 0 ?
                                                    <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                                        <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                            <DashboardTaskStatus
                                                                projectno={projectStatus.project.projectno}
                                                                activityno={projectStatus.audits[i].activity.activityno}
                                                                catValues={projectStatus.audits[i]?.actionByStatus}/>
                                                        </div>
                                                        <div className="link"
                                                             onClick={this.gotoTasks(projectStatus.project.projectno, projectStatus.audits[i].activity.activityno)}>{projectStatus.audits[i]?.actions.length}&nbsp;Tasks
                                                        </div>
                                                    </Col>
                                                    : <Col/>
                                                : null}


                                        </React.Fragment>
                                    )
                                }
                            )}

                    </Row>
                    {
                        projectStatus.ppapstati.length > 0 ?
                            <DashboardPpapStatus projectStatus={projectStatus}/> :
                            null
                    }
                    {
                        projectStatus.gantt.data.length > 0 ?
                            <>
                                <Row>
                                    <Col>
                                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>
                                            <hr style={{margin: '0.25rem 0 '}}/>
                                            Gantt Chart
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} style={{paddingTop: 0, paddingLeft: 0}}>
                                        <div style={{display: 'block', width: '100%'}}>
                                            <DashboardGanttChart
                                                from={projectStatus.project.startdate}
                                                to={projectStatus.project.duedate}
                                                data={projectStatus.gantt.data}/>
                                        </div>
                                    </Col>
                                </Row>
                            </> :
                            null
                    }


                </CardBody>
            </Card>
        )
    }
}



