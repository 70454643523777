import {AxiosRequestConfig} from "axios";

export const authorizer = (config?: AxiosRequestConfig) => {
    if (config) {
        config.headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
            Authorization: `Bearer ${sessionStorage.getItem('id_token')}`
        };

    } else {
        config = {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0',
                Authorization: `Bearer ${sessionStorage.getItem('id_token')}`
            }
        }
        return config;
    }
    return config;
};