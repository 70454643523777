import {BaseStore, IBaseStore} from "./base-store";
import {IUsergroup} from "../models/Usergroup";
import {Dataset} from "../components/dataset/dataset";
import {IUser, usergroupUserColumns} from "../models/User";
import {
    AuditModuleRights,
    INSPECTIONModuleRights,
    IUsergroupRight,
    PPAPModuleRights,
    usergrouprightColumns
} from "../models/UsergroupRight";
import {action, observable} from "mobx";
import {dsState, IDataset} from "../components/dataset/IDataset";
import {IField} from "../components/dataset/IField";
import {RouterState} from "mobx-state-router";
import * as R from 'ramda';
import {ILangKeys} from "../language/ILang";
import {UsergroupRight} from "../models/rights/enum-usergrouprights";


export interface ITreeNode {
    id: number;
    text: ILangKeys;
    module?: 'AUDIT' | 'PPAP' | 'IM';
    isOpen?: boolean;
    isEnabled?: boolean;
    nodes?: ITreeNode[];
}

export interface IUsergroupStore extends IBaseStore<IUsergroup> {
    dsUser: IDataset<IUser>;
    dsUsergroupRight: IDataset<IUsergroupRight>;
    treeNodes: ITreeNode[];
    setRight: (right: UsergroupRight, enabled: boolean) => Promise<void>;
}

export class UsergroupStore extends BaseStore<IUsergroup> implements IUsergroupStore {

    @observable
    dsUser: IDataset<IUser> = null;

    @observable
    dsUsergroupRight: IDataset<IUsergroupRight> = null;

    onAfterOpendsUsergroupRight = (ds: IDataset<IUsergroupRight>) => {
        // nicht definierte Rechte ausblenden.
        const {authStore: {hasModuleRight}} = this.rootStore;
        ds.data = ds.data
            .filter(usergroup => !R.includes(usergroup.rightno, AuditModuleRights) || hasModuleRight('AUDIT'))
            .filter(usergroup => !R.includes(usergroup.rightno, PPAPModuleRights) || hasModuleRight('PPAP'))
            .filter(usergroup => !R.includes(usergroup.rightno, INSPECTIONModuleRights) || hasModuleRight('IM'));
        //

        this.updateTree()

    }


    constructor(rootStore: any, dataUrl: string, columns: IField<IUsergroup>[], filter?: any) {
        super(rootStore, dataUrl, columns, filter);

        this.dsUser = new Dataset<IUser>('/gridApi/user/', usergroupUserColumns);
        this.dsUser.setMasterSource(this.ds, [
            {
                field: 'usergroupno',
                masterField: 'usergroupno'
            }
        ]);

        this.dsUsergroupRight = new Dataset<IUsergroupRight>('/gridApi/usergroupright/', usergrouprightColumns);
        this.dsUsergroupRight.onAfterOpen = this.onAfterOpendsUsergroupRight;
        this.dsUsergroupRight.setMasterSource(this.ds, [
            {
                field: 'usergroupno',
                masterField: 'usergroupno'
            }
        ])
    }


    @action.bound
    async openDetails() {
        await this.dsUser.open();
        await this.dsUsergroupRight.open();

    }

    @action.bound
    closeDetails() {
        this.dsUsergroupRight.close();
        this.dsUser.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        if (toState.routeName === 'usergrouptable') {
            await this.open();
            if (fromState.routeName === 'usergroup') {
                this.ds.locate(this.ds.pkFields, fromState.params)
            }
        } else if (toState.routeName === 'usergroup') {
            await this.open(toState.params as any);
            /*this.ds.edit();*/
            await this.openDetails();
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'usergroup') {
            if (this.rootStore.authStore.username !== '') {
                /*await this.ds.post();*/
            }
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'usergrouptable') {
            this.close();
            Promise.resolve();
        }
    }

    // RechteBaum
    treeNodes: ITreeNode[] = observable([
        {
            id: UsergroupRight.UR_HOME,
            text: "HOME",
            nodes: [
                {
                    id: UsergroupRight.UR_FILTER_ALL,
                    text: 'FILTER_ALL'
                },
            ]
        },
        {
            id: UsergroupRight.UR_CONF_CONFIGURATION,
            text: "CONFIGURATION",
        },
        {
            id: UsergroupRight.UR_ADMIN_SIDEBAR,
            text: "ADMINISTRATION",
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_ADMIN_USERGROUP,
                    text: "USERGROUPS",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_ADMIN_USERGROUP_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_ADMIN_USERGROUP_DELETE,
                            text: 'DELETE'
                        },
                    ]
                },
                {
                    id: UsergroupRight.UR_ADMIN_USER,
                    text: "USERS",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_ADMIN_USER_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_ADMIN_USER_DELETE,
                            text: 'DELETE'
                        },
                    ]
                }

            ]
        },
        {
            id: UsergroupRight.UR_BASE_SIDEBAR,
            text: "BASEDATA",
            isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_BASE_PRODUCT,
                    text: "PRODUCTS",
                    nodes: [
                        {
                            id: UsergroupRight.UR_BASE_PRODUCT_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_BASE_PRODUCT_DELETE,
                            text: 'DELETE'
                        },
                        {
                            id: UsergroupRight.UR_BASE_PRODUCT_EDIT,
                            text: 'EDIT'
                        },
                    ]
                },
                {
                    id: UsergroupRight.UR_BASE_CUSTOMER,
                    text: "CUSTOMERS",
                    nodes: [
                        {
                            id: UsergroupRight.UR_BASE_CUSTOMER_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_BASE_CUSTOMER_DELETE,
                            text: 'DELETE'
                        },
                    ]
                },
                {
                    id: UsergroupRight.UR_BASE_SUPPLIER,
                    text: "SUPPLIER",
                    nodes: [
                        {
                            id: UsergroupRight.UR_BASE_SUPPLIER_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_BASE_SUPPLIER_DELETE,
                            text: 'DELETE'
                        },
                    ]
                },
                {
                    id: UsergroupRight.UR_BASE_CATALOG,
                    text: 'CATALOGS',
                    nodes: [
                        {
                            id: UsergroupRight.UR_BASE_CATALOG_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_BASE_CATALOG_DELETE,
                            text: 'DELETE'
                        },
                    ]
                }
            ]
        },
        {
            id: UsergroupRight.UR_PROJECT_SIDEBAR,
            isEnabled: true,
            text: "PROJECTMANAGEMENT",
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_PROJECT_TEMPLATE,
                    text: 'PROJECTTEMPLATES',
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_PROJECT_TEMPLATE_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_PROJECT_TEMPLATE_DELETE,
                            text: 'DELETE'
                        },

                    ]
                },
                {
                    id: UsergroupRight.UR_PROJECT_PROJECT,
                    text: 'PROJECTS',
                    isOpen: true,
                    nodes: [

                        {
                            id: UsergroupRight.UR_PROJECT_PROJECT_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_PROJECT_PROJECT_DELETE,
                            text: 'DELETE'
                        },
                        {
                            id: UsergroupRight.UR_PROJECT_ACTIVITY_INSERT,
                            text: 'PROJECT_ACTIVITY_ADD'
                        },
                        {
                            id: UsergroupRight.UR_PROJECT_ACTIVITY_DELETE,
                            text: 'PROJECT_ACTIVITY_DELETE'
                        }

                    ]
                }
            ]
        },
        {
            id: UsergroupRight.UR_PPAP_SIDEBAR,
            text: "PPAP",
            module: 'PPAP',
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_PPAP_TEMPLATE,
                    text: "PPAP_STANDARD",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_PPAP_TEMPLATE_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_PPAP_TEMPLATE_DELETE,
                            text: 'DELETE'
                        },
                    ]
                },
                {
                    id: UsergroupRight.UR_PPAP_PPAP,
                    text: "PPAP_ORDERS",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_PPAP_PPAP_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_PPAP_PPAP_DELETE,
                            text: 'DELETE'
                        },
                    ]
                },
            ]
        },
        {
            id: UsergroupRight.UR_AUDIT_SIDEBAR,
            text: "AUDIT",
            //isOpen: true,
            module: 'AUDIT',
            nodes: [
                {
                    id: UsergroupRight.UR_AUDIT_TEMPLATE,
                    text: "AUDIT_TEMPLATES",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_AUDIT_TEMPLATE_INSERT,
                            text: "ADD"
                        },
                        {
                            id: UsergroupRight.UR_AUDIT_TEMPLATE_DELETE,
                            text: 'DELETE'
                        }
                    ]
                },
                {
                    id: UsergroupRight.UR_AUDIT_AUDIT,
                    text: "AUDITS",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_AUDIT_AUDIT_INSERT,
                            text: "ADD"
                        },
                        {
                            id: UsergroupRight.UR_AUDIT_AUDIT_DELETE,
                            text: 'DELETE'
                        }
                    ]
                }
            ]

        },
        {
            id: UsergroupRight.UR_INSPECTION_SIDEBAR,
            text: "INSPECTION",
            module: 'IM',
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_INSPECTION_PLAN,
                    text: "INSPECTION_PLAN",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_INSPECTION_PLAN_INSERT,
                            text: "ADD",
                        },
                        {
                            id: UsergroupRight.UR_INSPECTION_PLAN_DELETE,
                            text: "DELETE",
                        }
                    ]
                },
                {
                    id: UsergroupRight.UR_INSPECTION_INSPECTION,
                    text: "INSPECTION",
                    isOpen: true,
                    nodes: [
                        {
                            id: UsergroupRight.UR_INSPECTION_INSPECTION_INSERT,
                            text: "ADD",
                        },
                        {
                            id: UsergroupRight.UR_INSPECTION_INSPECTION_DELETE,
                            text: "DELETE",
                        }
                    ]
                }
            ]
        },
        {
            id: UsergroupRight.UR_GEMBA_SIDEBAR,
            text: 'GEMBAS',
            nodes: [
                {
                    id: UsergroupRight.UR_GEMBA_TEMPLATE,
                    text: 'GEMBA_TEMPLATES',
                    nodes: [
                        {
                            id: UsergroupRight.UR_GEMBA_TEMPLATE_INSERT,
                            text: 'ADD'
                        },
                        {
                            id: UsergroupRight.UR_GEMBA_TEMPLATE_DELETE,
                            text: 'DELETE'
                        },
                        {
                            id: UsergroupRight.UR_GEMBA_TEMPLATE_EDIT,
                            text: 'EDIT'
                        }
                    ]
                },
                {
                    id: UsergroupRight.UR_GEMBA_GEMBA,
                    text: 'GEMBAS'
                }
            ]
        },
        {
            id: UsergroupRight.UR_TASK_SIDEBAR,
            text: "ACTIONS",
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_TASK_TASK,
                    text: "ACTIONS",
                    isOpen: true,
                    nodes: [
                        /*{
                            id: UsergroupRight.UR_TASK_TASK_FILTER_RESPONSIBLE,
                            text: 'FILTER_ALL'
                        },*/
                        {
                            id: UsergroupRight.UR_TASK_TASK_INSERT,
                            text: "ADD"
                        },
                        {
                            id: UsergroupRight.UR_TASK_TASK_DELETE,
                            text: 'DELETE'
                        }
                    ]
                }
            ]
        },
        {
            id: UsergroupRight.UR_ACTIVITY_SIDEBAR,
            text: "PROJECTTASK_ACTIVITY",
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_ACTIVITY_ACTIVITIES,
                    text: "PROJECTTASK_ACTIVITIES",

                }
            ]
        },
        {
            id: UsergroupRight.UR_MOBILE_SIDEBAR,
            text: "MOBILE",
            //isOpen: true,
            nodes: [
                {
                    id: UsergroupRight.UR_MOBILE_ACTIVITIES,
                    text: "MOBILE_ACTIVITIES",
                }
            ]
        }
    ]);

    iterator = (node: ITreeNode) => {
        let record = this.dsUsergroupRight.data.find((record) => record.rightno === node.id);
        if (record) {
            node.isEnabled = record.enabled;
            node.nodes && node.nodes.forEach(this.iterator)
        }
    }

    @action.bound
    updateTree() {
        if (this.dsUsergroupRight.state === dsState.dsBrowse) {
            this.treeNodes.forEach(this.iterator)
        }
    }

    @action.bound
    async setRight(right: UsergroupRight, enabled: boolean) {
        this.dsUsergroupRight.locate(['rightno'], {rightno: right});
        this.dsUsergroupRight.edit();
        this.dsUsergroupRight.actual.enabled = enabled;
        return await this.dsUsergroupRight.post();
    }


}