import {BaseStore, IBaseStore} from "./base-store";
import {auditColumns, IAudit} from "../models/Audit";
import {IDataset} from "../components/dataset/IDataset";
import {auditQuestionColumns, AuditScore, IAuditQuestion, scoreText, scoreTextBinary} from "../models/AuditQuestion";
import {auditQuestionImageColumns, IAuditQuestionImage} from "../models/AuditQuestionImage";
import {actionColumns, ActionTyp, auditActionColumns, IAction} from "../models/Action";
import {action, computed, observable, reaction, runInAction} from "mobx";
import moment from "moment";
import {IRootStore} from "../routes/root-store";
import {Status, STATUS_ALL} from "../models/Status";
import {Dataset} from "../components/dataset/dataset";
import {RouterState} from "mobx-state-router";
import * as R from "ramda";
import axios from "axios";
import {ICatValue} from "../components/graphic/bar-graph";
import {RatingMethod} from "../models/AuditTemplate";
import {ISupplier, supplierColumns} from "../models/Supplier";
import {customerColumns, ICustomer} from "../models/Customer";
import {IProject, projectColumns} from "../models/Project";
import {authorizer} from "../components/dataset/authorizer";
import {cl_gray600, cl_green, cl_red, cl_yellow} from "../views/_theme/colors";
import {resizeImage} from "../lib/resize-image";


export interface IArea {
    label: string;
    completed: boolean;
    enabled: boolean;
}


export interface IElementNode {
    element: string;
    children: IAuditQuestion[];
    open?: boolean;
}

export interface IAreaNode {
    area: string;
    children: IElementNode[];
    open?: boolean;
}

export const auditScoreText = (ratingMethod: RatingMethod, score: number): string => {
    if (ratingMethod) {
        if (score === AuditScore.UNRATED) {
            return ''
        } else if (score === AuditScore.NOT_APPLICABLE) {
            return 'n.a.'
        } else {
            switch (ratingMethod) {
                case RatingMethod.VESTAS_SBA:
                    return (score * 10).toString()
                default:
                    return score.toString()
            }
        }
    } else return ''
}


export const auditScoreColors = (ratingMethod: RatingMethod, score: number): { bg: string, color: string } => {
    const red = {
        color: 'white',
        bg: cl_red
    };
    const yellow = {
        color: 'white',
        bg: cl_yellow
    };
    const green = {
        color: 'white',
        bg: cl_green
    };
    const na = {
        color: 'white',
        bg: cl_gray600
    };
    const unrated = {
        color: 'white',
        bg: 'white'
    };

    if (ratingMethod) {
        if (score === AuditScore.UNRATED) {
            return unrated
        } else if (score === AuditScore.NOT_APPLICABLE) {
            return na
        }
        switch (ratingMethod) {
            case RatingMethod.VESTAS_SBA:
                if (score >= 7) {
                    return green
                } else if (score > 5) {
                    return yellow
                } else {
                    return red
                }
            case RatingMethod.VDA61:
            case RatingMethod.VDA63:
            case RatingMethod.BINARY:
                if (score >= 8) {
                    return green
                } else if (score >= 6) {
                    return yellow
                } else {
                    return red
                }
        }
    } else {
        return {bg: 'white', color: 'white'}
    }
}

export interface IAuditImage extends IAuditQuestionImage {
    src: string;
    altText: string;
    caption: string;
    dataUrl?: string;
    action: 'NO' | 'INSERT' | 'DELETE';
}


export interface IAuditOnlineStore extends IBaseStore<IAudit> {
    dsAuditQuestion: IDataset<IAuditQuestion>;
    dsImage: IDataset<IAuditQuestionImage>;
    dsAction: IDataset<IAction>;
    dsReportImages: IDataset<IAuditQuestionImage>;
    dsProject: IDataset<IProject>;
    dsSupplier: IDataset<ISupplier>;
    dsCustomer: IDataset<ICustomer>;
    //
    auditScore: number; //0 bis 100
    actionByQuestionFilter: boolean;
    toggleActionFilter: () => Promise<void>;
    uploadFile: (acceptFile: any[]) => Promise<void>;
    deleteFile: (imageno: number) => Promise<void>;
    canDeleteFile: boolean;
    //
    progressValue: number;
    progressText: string;
    //
    areas: IArea[];
    actArea: string;
    setArea: (area: string) => void;
    elements: IArea[];
    actElement: string;
    setElement: (element: string) => void;
    //
    /*calculatedScore: number;*/
    //
    titleOpen: boolean;
    toggleTitleOpen: () => void;
    //
    nextElement: () => void;
    nextArea: () => void;
    prevElement: () => void;
    prevArea: () => void;

    //
    images: IAuditImage[];

    //
    getCatValue: (data: any[], groupfield: string, valuefield: string, filter?: (x: any) => boolean) => ICatValue[];

    SBAScore: number;

    // Evaluation
    evaluation: 'SCORE' | 'AREA';
    setEvaluation: (evaluation: 'SCORE' | 'AREA') => void;

    questionScoreFilter: number;
    setQuestionScoreFilter: (score: number) => void;

    generateQuestionaere: () => void;
    questionaere: IAreaNode[];
    questArea: string;
    setQuestArea: (area: string) => void;
    questElement: string;
    setQuestElement: (element: string) => void;

    toggleArea: (auditno: string, area: string, value: boolean) => Promise<any>;
    //
    saveLeadauditor: string;
    statusOptionsFunc: () => number[];

}


export class AuditOnlineStore extends BaseStore<IAudit> implements IAuditOnlineStore {

    /**
     /**
     *  dient als Zwischenspeicher des aktuellen Owners, wenn Form in Edit Mode geht und der Owner geändert wird.
     */
    saveLeadauditor: string;

    @action.bound
    dsOnAfterInsert(ds: IDataset<IAudit>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
    }

    @action.bound
    dsOnAfterEdit(ds: IDataset<IAudit>) {
        this.saveLeadauditor = ds.actual.leadauditor;
    }

    @observable
    dsAuditQuestion: IDataset<IAuditQuestion>;

    @action.bound
    dsAuditQuestiononAfterOpen(ds: IDataset<IAuditQuestion>) {

        ds.data = ds.data
            .filter((record) => !!!record.unselected)
            .sort((a, b) => {
                if (a.questionno < b.questionno) {
                    return -1
                }
                if (a.questionno > b.questionno) {
                    return 1
                }
                return 0
            });

        //ds.cursor=ds.data.length? 0:undefined;


        if (this.questionScoreFilter) {
            ds.data = ds.data.filter((record) => record.score <= this.questionScoreFilter && record.score !== AuditScore.NOT_APPLICABLE)
        }

        this.generateQuestionaere();


    }


    @observable
    dsAction: IDataset<IAction>;

    @observable
    dsImage: IDataset<IAuditQuestionImage>;

    @observable
    dsReportImages: IDataset<IAuditQuestionImage>;


    @action.bound
    dsActionOnAfterInsert(ds: IDataset<IAction>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.typ = ActionTyp.AUDITTask;
        ds.actual.createdby = username;
        ds.actual.taskowner = username;
        ds.actual.createdat = moment().format();
        ds.actual.key1 = this.dsAuditQuestion.actual.auditno;
        ds.actual.key2 = this.dsAuditQuestion.actual.questionno;
    }

    @observable
    actionByQuestionFilter: boolean = true;

    @observable
    questionByAreaFilter: boolean;

    @observable
    questionScoreFilter: number = undefined

    @observable
    dsProject: IDataset<IProject>;

    @observable
    dsSupplier: IDataset<ISupplier>;

    @observable
    dsCustomer: IDataset<ICustomer>;

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/audit/', R.clone(auditColumns));
        //
        // StatusColumn im Filter anpassen.
        let status = this.cdsFilter.columns
            .find(column => column.fieldName === 'status');
        status.options = [STATUS_ALL, Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        status.defaultValue = STATUS_ALL;
        //
        this.ds.onAfterInsert = this.dsOnAfterInsert;
        this.ds.onAfterEdit = this.dsOnAfterEdit;
        //
        this.dsAuditQuestion = new Dataset<IAuditQuestion>('/gridApi/auditquestion/', auditQuestionColumns);
        this.dsAuditQuestion.setMasterSource(this.ds, [{field: 'auditno', masterField: 'auditno'}]);
        this.dsAuditQuestion.onAfterOpen = this.dsAuditQuestiononAfterOpen;


        this.dsAction = new Dataset<IAction>('/gridApi/action/', R.clone(auditActionColumns), {typ: ActionTyp.AUDITTask});
        this.dsAction.setMasterSource(this.dsAuditQuestion, this.actionFilter);
        this.dsAction.onAfterInsert = this.dsActionOnAfterInsert;

        this.dsImage = new Dataset<IAuditQuestionImage>('/gridApi/auditquestionimage/', auditQuestionImageColumns);
        this.dsImage.setMasterSource(this.dsAuditQuestion, [
            {
                field: 'auditno',
                masterField: 'auditno'
            },
            {
                field: 'questionno',
                masterField: 'questionno'
            }]
        );

        this.dsReportImages = new Dataset<IAuditQuestionImage>('/gridApi/auditquestionimage/', auditQuestionImageColumns);
        this.dsReportImages.setMasterSource(this.dsAuditQuestion, [
            {
                field: 'auditno',
                masterField: 'auditno'
            }]
        );

        this.dsProject = new Dataset<IProject>('/gridApi/project/', projectColumns);
        this.dsProject.setMasterSource(this.ds, [
            {
                field: 'projectno',
                masterField: 'projectno'
            }]
        );

        this.dsSupplier = new Dataset<ISupplier>('/gridApi/supplier/', supplierColumns);
        this.dsSupplier.setMasterSource(this.dsProject, [
            {
                field: 'supplierno',
                masterField: 'supplierno'
            }]
        );

        this.dsCustomer = new Dataset<ICustomer>('/gridApi/customer/', customerColumns);
        this.dsCustomer.setMasterSource(this.dsProject, [
            {
                field: 'customerno',
                masterField: 'customerno'
            }]
        );
    }

    @action.bound
    async openDetails() {
        this.saveLeadauditor = '';
        await this.dsProject.open();
        await this.dsSupplier.open();
        await this.dsCustomer.open();
        this.dsAuditQuestion.autoRefresh = false; // !!!!!
        await this.dsAuditQuestion.open();
        //console.log(this.dsAuditQuestion.data)
        this.setArea(this.dsAuditQuestion.actual?.area);
        this.setElement(this.dsAuditQuestion.actual?.element);
        //
        // hier muss für das responsible Feld die Url: /gridapi/projectmember/<projectno> gesetzt werden.
        // Da columns im constructor gecloned sind, dürfte das keine Seiteneffekte haben
        const responsible = this.dsAction.columns.find((column) => column.fieldName === 'responsible');
        responsible.selectdlg.url = '/gridApi/projectmember/?projectno=' + this.dsProject.actual?.projectno;
        responsible.selectdlg.InserRight = undefined;
        await this.dsAction.open();
        //
        await this.dsImage.open();
        runInAction(() => {
            try {
                if (this.ds?.actual?.ratingmethod === RatingMethod.BINARY) {
                    let column = this.dsAuditQuestion.columns
                        .find(column => column.fieldName === 'score');
                    if (column) {
                        column.textFunc = scoreTextBinary;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        })
    }


    disposeMe = reaction(() => this.ds.actual?.projectno, (projectno) => {
        if (projectno) {
            this.ds.columns.find((column) => column.fieldName === 'leadauditor')
                .selectdlg.url = '/gridApi/projectuser/' + projectno;
            this.ds.columns.find((column) => column.fieldName === 'coauditor01')
                .selectdlg.url = '/gridApi/projectuser/' + projectno;
            this.dsAction.columns.find((column) => column.fieldName === 'responsible')
                .selectdlg.url = '/gridApi/projectuser/' + projectno;
        } else {
            this.ds.columns.find((column) => column.fieldName === 'leadauditor')
                .selectdlg.url = '/gridApi/user/';
            this.ds.columns.find((column) => column.fieldName === 'coauditor01')
                .selectdlg.url = '/gridApi/user/';
            this.dsAction.columns.find((column) => column.fieldName === 'responsible')
                .selectdlg.url = '/gridApi/user/';
        }
    })


    @action.bound
    closeDetails() {
        this.saveLeadauditor = '';
        this.dsImage.close();
        this.dsAction.close();
        this.dsAuditQuestion.close();
        this.dsSupplier.close();
        this.dsCustomer.close();
        this.dsProject.close();
    }

    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.dataUrl = '/gridApi/audit/'
        this.dsCheck.filter = {auditno: toState.params.auditno};
        await this.dsCheck.open();
        const rv = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    }


    /**
     *  Der wechsel von collect zu colllectcollect und umgekehr erfolgt ohne schliessen und öffnen.
     * @param fromState
     * @param toState
     */
    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        // wozu brauch ich diese Zeile hier?
        // un vor allem, warum hier zum Anfang?
        this.dsAuditQuestion.columns.find(column => column.fieldName === 'score').textFunc = scoreText;
        switch (toState.routeName) {
            case 'audittable':
                await this.loadFilter('audit');
                await this.open(this.cdsFilter.actual);
                if (fromState.routeName === 'audit' || fromState.routeName === 'auditcollect') {
                    this.ds.locate(this.ds.pkFields, fromState.params)
                }
                break;

            case 'audit':
                // this.ds muss geschlossen sein sonst knallst
                let column = this.ds.columns.find(col => col.fieldName === 'leadauditor')
                if (column) {
                    // Hier kracht es wenn man beim 2. mal ausführen
                    column.selectdlg.filter = {projectno: this.ds.actual?.projectno};
                }
                column = this.ds.columns.find(col => col.fieldName === 'coauditor01')
                if (column) {
                    column.selectdlg.filter = {projectno: this.ds.actual?.projectno};
                }

                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                // mögliche Leadauditor bzw. CoAuditor  Aspiranten aus dem Projectmember Pool speisen


                await this.openDetails();
                if (this.dsAuditQuestion.data.length > 0) {
                    this.setQuestArea(this.dsAuditQuestion.actual.area);
                    this.setQuestElement(this.dsAuditQuestion.actual.element)
                    // Rücksprung von Task
                    if (fromState.routeName === 'action') {
                        // Action laden , key2 ist Frage nummer
                        // Ist das schlau hier ein neues Object zu erzeugen (Garbage Collector)
                        const action = new Dataset<IAction>('/gridApi/action/', actionColumns, {no: fromState.params.no});
                        await action.open();
                        if (action.actual) {
                            this.dsAuditQuestion.locate(['questionno'], {questionno: action.actual.key2})
                        }
                        action.close();
                    } else if (toState.queryParams?.questionno) {
                        this.dsAuditQuestion.locate(['questionno'], {questionno: toState.queryParams.questionno})
                    }
                }
                break;

            case'auditreport':
                fromState.params.no = parseInt(fromState.params['no']) as any;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                if (fromState.routeName === 'auditquestion') {
                    this.dsAuditQuestion.locate(this.dsAuditQuestion.pkFields, fromState.params);
                }
                await this.dsReportImages.open();
                break;


            case 'auditcollectonline':
                // Warum diese Bedingung, macht doch kein Sinn
                /*if (fromState.routeName !== 'auditcollectcollectonline') {*/
                this.titleOpen = true;
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                /* }*/
                await Promise.resolve();
                break;

            case 'auditcollectcollectonline':
                if (fromState.routeName !== 'auditcollectonline') {
                    this.titleOpen = true;
                    await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                    await this.openDetails();
                }
                this.dsAuditQuestion.edit(); // wg. #756
                await Promise.resolve();
                break;
        }
    }

    /**
     *  Der wechsel von collect zu colllectcollect und umgekehr erfolgt ohne schliessen und öffnen.
     * @param fromState
     * @param toState
     */
    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        switch (fromState.routeName) {

            case  'audit':
                this.dsReportImages.close();
                this.closeDetails();
                this.close();
                await Promise.resolve();
                break;
            case 'auditreport':
                await this.dsReportImages.close();
                this.closeDetails();
                this.close();
                await Promise.resolve();
                break;
            case 'audittable':
                this.close();
                await Promise.resolve();
                break;
            case 'auditcollect':
                if (toState.routeName !== 'auditcollectcollect') {
                    this.closeDetails();
                    this.close();
                }
                await Promise.resolve();
                break;
            case 'auditcollectcollectonline':
                if (this.rootStore.authStore.username !== '') {
                    await this.dsAuditQuestion.post();
                }
                if (toState.routeName !== 'auditcollectonline') {
                    this.closeDetails();
                    this.close();
                }
                await Promise.resolve();
                break;
        }
    }

    @computed
    get actionFilter() {
        if (this.actionByQuestionFilter) {
            return [
                {
                    field: 'key1',
                    masterField: 'auditno'
                },
                {
                    field: 'key2',
                    masterField: 'questionno'
                }
            ]

        } else {
            return [
                {
                    field: 'key1',
                    masterField: 'auditno'
                },
            ]
        }
    }

    /**
     *  Der "normale" AuditScore:
     *  Mittelwert aus allen bewerteten Fragen
     */
    @computed
    get auditScore(): number {
        const ratingMethod = this.ds.actual?.ratingmethod;

        const target = this.dsAuditQuestion
            .data
            .filter((x) => x.score !== AuditScore.UNRATED && x.score !== AuditScore.NOT_APPLICABLE)
            .reduce((sum: number, record: any) => {
                if (record.score >= 0) {
                    if (ratingMethod === RatingMethod.VESTAS_SBA && record.weighting) {
                        sum = sum + 10 * record.weighting;
                    } else {
                        sum = sum + 10;
                    }
                }
                return sum
            }, 0);
        //.length * 10;

        let score = this.dsAuditQuestion
            .data
            .filter((x) => x.score !== AuditScore.UNRATED && x.score !== AuditScore.NOT_APPLICABLE)
            .reduce((sum: number, record: any) => {
                if (record.score >= 0) {
                    if (ratingMethod === RatingMethod.VESTAS_SBA && record.weighting) {
                        sum = sum + record.score * record.weighting;
                    } else {
                        sum = sum + record.score;
                    }
                }
                return sum
            }, 0);


        return target ? score / target * 100 : 0;
    }


    /**
     * Berechnung des gewichteten Scores des SBA Audits
     * Mittelwert aus 2 Scores:
     *   1) Field: Chapter==='MS'
     *      Gewichteter Mittelwerte aus den Mittelwerten der Areas
     *      Gewichtungsfaktor steht im Feld "weighting"
     *
     *   2) Field: Chappter==='TC'
     *     Mittelwert aus allen Fragen
     */
    @computed
    get SBAScore(): number {
        let anzahl = this.dsAuditQuestion
            .data.filter(record => record.chapter === 'TC' && record.score !== AuditScore.UNRATED && record.score !== AuditScore.NOT_APPLICABLE).length
        let tc_score = this.dsAuditQuestion
            .data
            .filter(record => record.chapter === 'TC' && record.score !== AuditScore.UNRATED && record.score !== AuditScore.NOT_APPLICABLE)
            .reduce((sum: number, record) => {
                    if (sum) {
                        sum = sum + record.score * 10;
                        return sum
                    } else {
                        return record.score * 10
                    }
                },
                undefined);
        if (anzahl) {
            tc_score = tc_score / anzahl;
        }
        //console.log('TC=', tc_score);


        const rawdata = this.getCatValue(
            this.dsAuditQuestion.data,
            'area',
            'score',
            (x: any) => x.chapter === 'MS' && x.score !== AuditScore.UNRATED && x.score !== AuditScore.NOT_APPLICABLE
        )
        let ms_score_base = rawdata.reduce((sum: { actual: number, total: number }, record) => {
                const factor = this.dsAuditQuestion.data.find(question => question.area === record.category).weighting
                if (sum.actual) {
                    sum.actual = sum.actual + record.value * factor ?? 1;
                    sum.total = sum.total + factor ?? 1
                    return sum
                } else {

                    return {actual: record.value * factor ?? 1, total: factor ?? 1}
                }
            },
            {actual: undefined, total: undefined});
        let ms_score: number = 0;
        if (rawdata.length) {
            ms_score = ms_score_base.actual / ms_score_base.total;
        }

        const rv = tc_score && ms_score ? (tc_score + ms_score) / 2 :
            tc_score ? tc_score :
                ms_score ? ms_score : 0

        //console.log('calc=', ms_score_base, tc_score, ms_score, rv, Math.floor(rv))

        return Math.floor(rv);


    }


    @action.bound
    async toggleActionFilter() {
        this.actionByQuestionFilter = !this.actionByQuestionFilter;
        this.dsAction.setMasterSource(this.dsAuditQuestion, this.actionFilter);
        await this.dsAction.refresh(0);
    }


    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/auditquestionimage/fileupload';
        let file: File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        const formData = new FormData();
        formData.append('type', 'auditquestionimage');
        formData.append('file', file);
        formData.append('auditno', this.dsAuditQuestion.actual.auditno);
        formData.append('questionno', this.dsAuditQuestion.actual.questionno.toString());
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        await axios.post(url, formData, config);
        await this.dsImage.refresh(this.dsImage.cursor);
        await runInAction(async () => {
            this.dsImage.last();
        });
    }

    @action.bound
    deleteFile = async (): Promise<void> => {
        await this.dsImage.delete();
    };

    @computed
    get canDeleteFile() {
        return this.dsImage.cursor !== undefined;
    }


    @computed
    get progressValue() {
        return (this.dsAuditQuestion.data.length === 0 ?
            0 :
            this.dsAuditQuestion.data
                .filter((question) => question.score !== AuditScore.UNRATED || question.notapplicable)
                .length / this.dsAuditQuestion.data.length) * 100;
    }

    @computed
    get progressText() {
        return this.dsAuditQuestion.data
            .filter((question) => question.score !== AuditScore.UNRATED || question.notapplicable)
            .length.toString() + ' / ' + this.dsAuditQuestion.data.length.toString()
    }


    @computed
    // Alle Areas die vorhanden sind.
    get areas(): IArea[] {
        //console.log(' calc Areas', this.dsAuditQuestion.state, this.dsAuditQuestion.data.length)
        let areas = R.uniq(this.dsAuditQuestion.data
            .map(question => {
                return {label: question.area, completed: true, enabled: true}
            })
        );

        const filtered = this.dsAuditQuestion.data
            .filter(question => question.score === AuditScore.UNRATED);

        areas
            .forEach(area => {
                if (filtered.find(f => f.area === area.label)) {
                    area.completed = false;
                }
            });

        areas.sort((first, second) => {
            return first.label < second.label ? -1 :
                first.label > second.label ? 1 :
                    0
        })

        return areas;
    }

    @action.bound
    setArea(area: string) {
        this.actArea = area;
        // dies ist ein Hilfskonstrukt, da das computed der elements-Liste zu diesem zeitpunkt, also Brute-Force
        const myElements = R.uniq(this.dsAuditQuestion.data
            .filter(question => question.area === area)
            .map(data => data.element));
        if (myElements.length > 0) {
            this.setElement(myElements[0])
        }
    }

    @observable
    actArea: string = '';

    @computed
    get elements(): IArea[] {
        let elements = R.uniq(this.dsAuditQuestion.data
            .filter(question => question.area === this.actArea)
            .map(question => {
                return {label: question.element, completed: true, enabled: true}
            })
        );
        const filtered = this.dsAuditQuestion.data
            .filter(question => question.score === AuditScore.UNRATED);


        elements
            .forEach(element => {
                if (filtered.find(f => f.element === element.label)) {
                    element.completed = false;
                }
            });

        elements.sort((first, second) => {
            return first.label < second.label ? -1 :
                first.label > second.label ? 1 :
                    0
        })

        return elements;
    }

    @action.bound
    setElement(element: string) {
        this.actElement = element;
    }

    @observable
    actElement: string = '';

    /*@computed
    get calculatedScore(): number {
        const notApplicable = this.dsAuditQuestion.actual?.notapplicable;
        const qm = this.dsAuditQuestion.actual?.qm;
        const praxis = this.dsAuditQuestion.actual?.praxis;
        //console.log(notApplicable, qm, praxis);
        if (notApplicable) {
            return AuditScore.NOT_APPLICABLE
        } else if (qm === 2 && praxis === 2) {
            return AuditScore.FULL_COMPLIANCE;
        } else if (qm === 0 && praxis === 2) {
            return AuditScore.PREDOMINANT_COMPLIANCE;
        } else if (qm === 2 && praxis === 1) {
            return AuditScore.PARTIAL_COMPLIANCE
        } else if (qm === 0 && praxis === 1) {
            return AuditScore.UNSATISFACTORY_COMPLIANCE;
        } else if (qm === 0 && praxis === 0) {
            return AuditScore.NO_COMPLIANCE;
        } else if (qm === 2 && praxis === 0) {
            return AuditScore.NO_COMPLIANCE;
        } else return AuditScore.UNRATED;
    }*/


    @observable
    titleOpen: boolean = true;

    @action.bound
    toggleTitleOpen() {
        this.titleOpen = !this.titleOpen;
    }


    // Actions for Navigation

    @action.bound
    nextElement() {
        let i = this.elements
            .findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return
        }
        if (this.elements.length === i + 1) {
            this.nextArea();
            return
        }
        this.setElement(this.elements[i + 1].label)
    }

    @action.bound
    nextArea() {
        let i = this.areas
            .findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return
        }
        if (i === this.areas.length - 1) {
            return
        }
        this.setArea(this.areas[i + 1].label)
    }

    @action.bound
    prevElement() {
        let i = this.elements
            .findIndex((element) => element.label === this.actElement);
        if (i === -1) {
            return
        }
        if (i === 0) {
            this.prevArea();
            return
        }
        //console.log('Element',i);
        this.setElement(this.elements[i - 1].label)
    }

    @action.bound
    prevArea() {
        let i = this.areas
            .findIndex((area) => area.label === this.actArea);
        if (i === -1) {
            return
        }
        if (i === 0) {
            return
        }
        //console.log('Area',i);
        this.setArea(this.areas[i - 1].label)
    }

    @computed
    get images(): IAuditImage[] {
        return this.dsImage
            .data
            .map(image => {
                return {
                    auditno: image.auditno,
                    questionno: image.questionno,
                    imageno: image.imageno,
                    image: image.image,
                    src: '/gridApi/image/auditquestionimage/' + image.auditno + '/' + image.questionno + '/' + image.image,
                    altText: image.image,
                    caption: image.image,
                    action: 'NO'
                }
            })
    }

    getCatValue = (data: any[], groupfield: string, valuefield: string, filter?: (x: any) => boolean): ICatValue[] => {

        // optionalen filter anwenden
        let _data: any[] = filter ? data.filter(filter) : data;

        // Kategorien bestimmen
        let catList = R.uniq(_data
            .map((question: any) => question[groupfield])
        );

        return catList
            .map((category => {
                let rv = {category: category, value: 0};

                let questions = _data
                    .filter((record: any) => record[groupfield] === category);


                if (questions.find(record => record.score === AuditScore.NOT_APPLICABLE)) {
                    if (questions.find(record => record.score !== AuditScore.NOT_APPLICABLE)) {
                        questions = questions.filter((record) => record.score !== AuditScore.NOT_APPLICABLE);
                        const maxScore = questions.length * 10;
                        const score = questions
                            .reduce((sum, record) => {
                                    sum = sum + record[valuefield];
                                    return sum
                                },
                                0);
                        console.log(maxScore, score);
                        rv.value = score / maxScore * 100;

                    } else {
                        rv.value = -20;
                    }
                } else {
                    const maxScore = questions.length * 10;
                    const score = questions
                        .reduce((sum, record) => {
                                sum = sum + record[valuefield];
                                return sum
                            },
                            0);
                    //console.log(maxScore, score);
                    rv.value = score / maxScore * 100;
                }


                return rv
            }));
    };


    // Evaluation
    @observable
    evaluation: 'SCORE' | 'AREA' = 'SCORE';

    @action.bound
    setEvaluation(evaluation: 'SCORE' | 'AREA') {
        this.evaluation = evaluation;
    }

    @action.bound
    async setQuestionScoreFilter(score: number) {
        this.questionScoreFilter = score;
        await this.dsAuditQuestion.refresh(this.dsAuditQuestion.cursor);
    }


    @action.bound
    generateQuestionaere(): void {
        this.questionaere = R.uniq(this.dsAuditQuestion
            .data
            .filter((record) => !this.questionScoreFilter || (record.score <= this.questionScoreFilter && record.score !== AuditScore.NOT_APPLICABLE))
            .map((aquestion) => {
                return {
                    area: aquestion.area,
                    open: false,
                    children: R.uniq(this.dsAuditQuestion.data
                        .filter(question => question.area === aquestion.area)
                        .map((equestion) => {
                            return {
                                element: equestion.element,
                                open: false,
                                children:
                                    this.dsAuditQuestion.data
                                        .filter(question => question.area === aquestion.area && question.element === equestion.element)
                            }
                        })
                    )
                }
            })
        );

        this.questionaere = this.questionaere.slice().sort((first, second) => first.area < second.area ? -1 : first.area > second.area ? 1 : 0)
    }

    @observable
    questionaere: IAreaNode[] = [];

    @observable
    questArea: string = '';

    @action.bound
    setQuestArea(area: string) {
        this.questArea = area;
    }

    @observable
    questElement: string = '';

    @action.bound
    setQuestElement(questElement: string) {
        this.questElement = questElement;
    }

    @action.bound
    async toggleArea(auditno: string, area: string, value: boolean) {
        let enabled: number = value ? 1 : 0;
        await axios.put('/gridApi/auditarea/' + auditno + '/' + area + '/' + enabled.toString() + '/', authorizer())
    }


    statusOptionsFunc = () => {
        const {authStore: {username}} = this.rootStore;
        if (username === this.dsProject.actual?.owner) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED, Status.NOTAPPROVED, Status.APPROVED];
        } else if (username === this.ds.actual?.leadauditor) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        } else {
            return []
        }
    }

}