import {inject, observer} from "mobx-react";
import * as React from "react";
import {Col, Container, Row} from "reactstrap";
import {BaseCard} from "../_base/card/base-card";
import {IBaseView} from "../_base/base-view";


@inject('rootStore')
@observer
export class Test2View extends React.Component<IBaseView, any> {


    render() {
        return (
            <Container>
                <Row style={{paddingTop: '1rem'}}>
                    <Col md={4}>
                        <BaseCard title={'TEST'} showBackButton={true} height={500}>

                        </BaseCard>
                    </Col>
                </Row>
            </Container>

        )
    }
}