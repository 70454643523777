import {dateCellRenderer} from "../components/dataset/IDataset";
import {customerColumns} from "./Customer";
import {userColumns} from "./User";
import {IField} from "../components/dataset/IField";
import {supplierColumns} from "./Supplier";
import {enumToArray} from "../components/lib/enumToArray";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {Status, StatusComplete, statusText} from "./Status";
import {DuedateRenderer} from "./_helper/duedate-renderer";
import {StatusRenderer} from "./cell-renderers/StatusRenderer";
import {catalogDetailColumns} from "./CatalogDetail";


export interface IProject {
    projectno: string;
    project: string;
    projectgroup: string;
    subgroup01: string;
    owner: string;
    status: number;
    startdate: string;
    duedate: string;
    customerno: string;
    customerresponsible: string;
    customer1: string; // View->Customer
    supplierno: string;
    supplier1: string; // View -> Supplier
    comment: string;
    createdby: string;
    createdat: string;
    extrafield1: string;
    extrafield2: string;
    extrafield3: string;
    extrafield4: string;
}


export const projectColumns: IField<IProject>[] = [
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
    },
    {
        fieldName: 'project',
        dataType: 'string',
        label: 'PROJECT_PROJECT',
    },
    /* {
         fieldName: 'projectgroup',
         dataType: 'string',
         label: 'PROJECT_PROJECTGROUP',
         useAsFilter: true,
     },*/
    {
        fieldName: 'projectgroup',
        dataType: 'string',
        label: 'PROJECT_PROJECTGROUP',
        useAsFilter: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10003',
            columns: catalogDetailColumns,
            field: 'description1',
        }
    },
    {
        fieldName: 'subgroup01',
        dataType: 'string',
        label: 'PROJECT_SUBGROUP01',
        useAsFilter: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10004',
            columns: catalogDetailColumns,
            field: 'description1',
        }
    },


    {
        fieldName: 'owner',
        dataType: 'string',
        label: 'PROJECT_OWNER',
        insert: 'show',
        rules: ['mandatory'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        useAsFilter: true,
    },
    {
        fieldName: 'status',
        dataType: 'number',
        label: 'PROJECT_STATUS',
        rules: ['number'],
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(StatusComplete),
        useAsFilter: true,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100
        },
        defaultValue: Status.PLANNED,
    },
    {
        fieldName: 'startdate',
        dataType: 'string',
        label: 'PROJECT_START',
        input: 'calendar',
        insert: 'show',
        grid: {
            cellRenderer: dateCellRenderer,
            width: 100
        }
    },
    {
        fieldName: 'duedate',
        dataType: 'string',
        label: 'PROJECT_TERMIN',
        input: 'calendar',
        insert: 'show',
        grid: {
            cellRenderer: DuedateRenderer,
            width: 100,
        }
    },
    {
        fieldName: 'customerno',
        label: 'CUSTOMER_CUSTOMERNO',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT
        },
        hide: 'table',
        useAsFilter: true,
    },
    {
        fieldName: 'customer1',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER1',
        readOnly: true,
    },
    {
        fieldName: 'customerresponsible',
        dataType: 'string',
        label: 'PROJECT_CUSTOMER_RESPONSIBLE',
        input: 'selectdlg',
        hide: 'always',
        selectdlg: {
            url: '/gridApi/user/',
            filter: (actual) => {
                return {customerno: actual['customerno']}
            },
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        useAsFilter: true,
    },

    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        insert: 'show',
        input: 'selectdlg',
        hide: 'table',
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT
        }

    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        readOnly: true,
    },
    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        hide: 'table'

    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
        hide: 'table'
    },

    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'PROJECT_COMMENT',
        hide: 'table',
        input: 'textarea'
    },
    {
        fieldName: 'extrafield1',
        dataType: 'string',
        label: 'PROJECT_EXTRAFIELD1',
        hide: 'table'
    },
    {
        fieldName: 'extrafield2',
        dataType: 'string',
        label: 'PROJECT_EXTRAFIELD2',
        hide: 'table'
    },
    {
        fieldName: 'extrafield3',
        dataType: 'string',
        label: 'PROJECT_EXTRAFIELD3',
        hide: 'table'
    },
    {
        fieldName: 'extrafield4',
        dataType: 'string',
        label: 'PROJECT_EXTRAFIELD4',
        hide: 'table',
    }
];