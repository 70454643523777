import {BaseStore, IBaseStore} from "./base-store";

import {action} from "mobx";
import {RouterState} from "mobx-state-router";
import {IActivity} from "../models/Activity";
import {activityColumns} from "../models/Activity";


export interface IActivityStore extends IBaseStore<IActivity> {

}


export class ActivityStore extends BaseStore<IActivity> implements IActivityStore {

    constructor(rootStore: any) {
        super(rootStore, '/gridApi/activity/', activityColumns);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.initializeAsync();
        await this.loadFilter('activity');
        await this.open(this.cdsFilter.actual);

        this.afterOpen(fromState, toState);
        switch (fromState.routeName) {
            case 'auditcollectoffline':
            case 'auditcollectonline':
                this.ds.locate(['link'], {link: fromState.params.auditno});
        }

    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        await this.beforeClose(fromState, toState);
    }


}