import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";
import {CardWithTab, ITab} from "../../../../_base/styled/card-with-tab";
import {DbGrid} from "../../../../../components/dbgrid/db-grid";
import {DbForm} from "../../../../../components/dbform/db-form";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../../../components/dbform/db-field";
import {TableEditMenu} from "../../../../_base/styled/table-edit-menu";
import {MenuInsert} from "../../../../_base/menu/menu-insert";
import {MenuDelete} from "../../../../_base/menu/menu-delete";
import {action} from "mobx";
import {AuditRating} from "../../../rating/audit-rating";
import {MenuFileUpload} from "../../../../_base/menu/menu-file-upload";
import {AuditDetailMainRightToggleDocument} from "./audit-detail-main-right-toggle-document";
import {ImageListView} from "../../../../_shared/image-list-view";
import {Status} from "../../../../../models/Status";
import {dsState} from "../../../../../components/dataset/IDataset";
import {RatingMethod} from "../../../../../models/AuditTemplate";


const QuestionMenu = inject('rootStore')(observer((props: IBaseView) => {
    const {rootStore: {auditStore: {ds, dsAction}, authStore: {username}}} = props;
    return ((username === ds.actual?.leadauditor || username === ds.actual?.coauditor01) && ds.actual.status !== Status.APPROVED ?
            <>
                <MenuInsert id={"ACTIONS_btn-insert"} ds={dsAction} text="Add Task"/>
            </>
            : null

    )
}))


const TasksMenu = inject('rootStore')(observer((props: IBaseView) => {
        const {rootStore: {auditStore: {ds, dsAction}, authStore: {username}}} = props;
        return (<>
                {(username === ds.actual?.leadauditor || username === ds.actual?.coauditor01) && ds.actual.status !== Status.APPROVED ?
                    <>
                        <MenuInsert id={"ACTIONS_btn-insert"} ds={dsAction}/>
                        <MenuDelete id={"ACTIONS_btn-delete"} ds={dsAction}/>
                    </>
                    : null
                }
                <AuditDetailMainRightToggleDocument id="toggleTaskDoc" icon="filter"/>
            </>


        )
    }
))


@inject('rootStore')
@observer
export class AuditDetailMainRightElement extends React.Component
    <IBaseView, {}> {

    @action.bound
    gotoTask() {
        const
            {
                rootStore: {
                    routerStore, auditStore:
                        {
                            dsAction,
                        }
                }
            }
                = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    getTabs = (): ITab[] => {

        const {
            rootStore: {
                authStore: {username},
                auditStore: {ds, dsAuditQuestion, dsAction, dsImage, uploadFile},
            }
        } = this.props;

        return [
            {
                title: 'AUDIT_QUESTION',
                icon: "question",

                content: (
                    <div style={{padding: '1rem'}}>
                        <DbForm ds={dsAuditQuestion}>
                            <Row>
                                <Col>
                                    <h5> {dsAuditQuestion.actual?.question}</h5>
                                </Col>

                            </Row>
                            {dsAuditQuestion.actual?.mandatory ?
                                <Row>
                                    <Col>
                                        <b>Mandatory</b>
                                    </Col>
                                </Row> :
                                null
                            }
                            <Row>
                                <Col>
                                    {ds.actual?.ratingmethod !== RatingMethod.BINARY ?
                                        <>
                                            <DbField ds={dsAuditQuestion} field="reference" readOnly={true}/>
                                            <DbField ds={dsAuditQuestion} field="requirements" readOnly={true}/>
                                        </> :
                                        null
                                    }

                                    <DbField ds={dsAuditQuestion} field="answer"/>
                                    {ds.actual?.ratingmethod !== RatingMethod.BINARY ?
                                        <>
                                            <DbField ds={dsAuditQuestion} field="evidence"/>
                                        </> :
                                        null
                                    }
                                    <AuditRating audit={ds.actual} question={dsAuditQuestion.actual}
                                                 readOnly={dsAuditQuestion.state !== dsState.dsEdit}/>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: '1rem'}}>
                                <Col>


                                </Col>
                            </Row>
                        </DbForm>
                    </div>
                ),
                menu: (
                    <>
                        <QuestionMenu/>
                        <TableEditMenu ds={dsAuditQuestion}
                                       hidden={!((username === ds.actual?.leadauditor || username === ds.actual?.coauditor01) && ds.actual.status !== Status.APPROVED)}/>
                    </>
                )
            },
            {
                title: 'IMAGES',
                icon: "images",
                content: (
                    <ImageListView dsImage={dsImage} imageBaseUrl="/gridApi/image/auditquestionimage/" key1="auditno"
                                   key2="questionno"/>
                ),
                menu: <div>
                    {((username === ds.actual?.leadauditor || username === ds.actual?.coauditor01) && ds.actual.status !== Status.APPROVED) ?
                        <>
                            <MenuFileUpload
                                id={"PROJECT_DOCUMENTS_btn-insert"}
                                onDrop={uploadFile}
                                disabled={dsAuditQuestion.cursor === undefined}
                            />
                            <MenuDelete
                                id={"PROJECT_DOCUMENTS_btn-delete"}
                                ds={dsImage}
                                disabled={dsAuditQuestion.cursor === undefined}
                            />
                        </> :
                        null
                    }
                </div>
            },
            {
                title: 'ACTIONS',
                icon: "tasks",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid
                            ds={dsAction}
                            onRowDoubleClicked={this.gotoTask}
                        />

                    </div>
                ),
                menu: <TasksMenu/>
            },
        ]
    }


    render() {
        return (
            <CardWithTab
                tabs={this.getTabs()}
            />

        )
    }
}