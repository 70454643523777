import * as React from "react";
import {IBaseView} from "../../../../_base/base-view";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {gembaFindingColumns, IGembaFinding} from "../../../../../models/GembaFinding";
import {Col, Row} from "reactstrap";
import {Field} from "../../../../../components/dbform/field/field";
import {IField} from "../../../../../components/dataset/IField";
import {MenuFileUpload} from "../../../../_base/menu/menu-file-upload";
import {IGembaFindingImage} from "../../../../../models/GembaFindingImage";
import {GembaFindingImageImg} from './gemba-finding-image-img';
import {action} from "mobx";
import {cl_blue} from "../../../../_theme/colors";

interface IFinding extends IBaseView {
    finding: IGembaFinding;
    active?: boolean;
    onClick: () => Promise<void>;
    areaColumn: IField<IGembaFinding>;
    //uploadFile: (acceptFile: any[]) => Promise<void>;
    accept?: string; // filter auf die Dateitypen die ausgewähltwerden können.
    images: IGembaFindingImage[];
}

@inject('rootStore')
@observer
class _GembaFinding extends React.Component<IFinding, {}> {

    @action.bound
    async onDrop(acceptFile: any[]) {
        const {rootStore: {gembaStore: {uploadFile}}, finding} = this.props;
        await uploadFile(acceptFile, finding);
    }


    render() {
        const {
            className,
            active,
            finding,
            onClick,
            accept,
            areaColumn,
            images
        } = this.props;

        return (
            <div className={className} onClick={onClick}>
                {
                    !!active ?
                        <>
                            <Row>
                                <Col>
                                    <Field
                                        actual={finding}
                                        column={areaColumn}
                                    />
                                    <Field
                                        actual={finding}
                                        column={gembaFindingColumns.find((column) => column.fieldName === "keyword")}
                                    />
                                </Col>
                            </Row>
                            <Row style={{alignItems: 'center'}}>
                                <Col xs={9}>
                                    <GembaFindingImageImg images={images}/>
                                </Col>
                                <Col xs={3}>
                                    <MenuFileUpload id={"xxx-btn-upload"} onDrop={this.onDrop} disabled={false}
                                                    accept={accept}/>
                                    {/* <MenuImageDelete id={"xxx-btn-delete"} disabled={!canDeleteFile}
                                                     onClickDelete={this.onClickDelete}/>*/}
                                    {/* <input accept="image/*" id="icon-button-file" type="file" capture="environment"/>*/}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        actual={finding}
                                        column={gembaFindingColumns.find((column) => column.fieldName === "finding")}
                                    />
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <div className={"area"}>
                                {finding.area ?? <div>&nbsp;</div>}

                            </div>
                            {finding.keyword ?? <div>&nbsp;</div>}
                            {!!active ? <><br/></> : null}
                        </>
                }


            </div>
        )
    }
}

export const GembaFinding = styled(_GembaFinding)`
  color: black;
  background-color: white;
  border-radius: 0.5rem;
  border: ${props => props.active ? '2px solid ' + cl_blue : props.theme.border};
  box-shadow: ${props => props.theme.boxShadow};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0.5rem;

  .area {
    font-size: 0.625rem;
  }
`;