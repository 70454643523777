import {ILangKeys} from "../language/ILang";
import {IField} from "../components/dataset/IField";
import {enumToArray} from "../components/lib/enumToArray";


export enum ICatalogType {
    //CAT_DECISION = 100,
    //CAT_AUDIT = 200,
    CAT_APQP_PHASE = 300,
    CAT_INSPECTION = 400,
    CAT_PROJECTGROUP = 500,
    CAT_PROJECTSUBGROUP01 = 600,
    CAT_GEMBA_AREA = 700,
    CAT_GEMBA_ELEMENT = 800
}


export const catalogTypeText = (type: ICatalogType): ILangKeys => {
    switch (type) {
        // case ICatalogType.CAT_DECISION:
        //     return 'DESCAT_TYPE_DECISION';
        // case ICatalogType.CAT_AUDIT:
        //     return 'DESCAT_TYPE_AUDIT';
        case ICatalogType.CAT_APQP_PHASE:
            return 'DESCAT_TYPE_APQP_PHASE';
        case ICatalogType.CAT_INSPECTION:
            return 'INSPECTION'
        case ICatalogType.CAT_PROJECTGROUP:
            return 'PROJECT_PROJECTGROUP';
        case ICatalogType.CAT_PROJECTSUBGROUP01:
            return 'PROJECT_SUBGROUP01'
        case ICatalogType.CAT_GEMBA_AREA:
            return 'GEMBA_AREA'
        case ICatalogType.CAT_GEMBA_ELEMENT:
            return 'GEMBA_ELEMENT'
        default:
            return 'Unknown';
    }
};


export interface ICatalog {
    catalogno: string;
    catalogtyp: number;
    description1: string;

}

export const catalogColumns: IField<ICatalog>[] = [
    {
        fieldName: 'catalogno',
        dataType: 'string',
        label: 'DESCAT_CATALOGNO',
        primaryKey: true,
        rules: ['pk'],
    },
    {
        fieldName: 'catalogtyp',
        dataType: 'number',
        label: 'DESCAT_TYPE',
        rules: ['number'],
        input: 'select',
        textFunc: catalogTypeText,
        options: enumToArray(ICatalogType),
        insert: 'show',
        readOnly: true,
        //defaultValue: ICatalogType.CAT_DECISION
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'DESCAT_DESCRIPTON1',
        insert: 'show',
        rules: ['mandatory']

    },

];


