import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../_base/base-view";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {TableGrid} from "../../_base/styled/table-grid";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";
import {BaseTableFilterMenu} from "../../_base/table-filter/base-table-filter-menu";


@inject('rootStore')
@observer
export class GembaTableView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {gembaStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="gemba"
                    icon="walking"
                    title='GEMBAS'
                    ds={ds}
                    singleRouteName='gemba'
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                            <BaseTableFilterMenu storeName="gembaStore"
                                                 filtername="gemba"/> :
                            null
                    }
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        )
    }
}